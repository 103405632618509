import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import AuthContext from "../context/AuthContext";
// import { HotCasinoData} from "../Utils/constants";
import { Link, useNavigation, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { IoMdArrowBack } from "react-icons/io";

const Info = () => {
    const { lang, user, launchEGTCasino, launchCasino, withoutLogin } =
        useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const parmas = useParams();
    // console.log(parmas.page);
    const [page, setPage] = useState(parmas.page);

    return (
        <LayoutNew>
            <div className="row info-container-row justify-center" style={{marginTop:`50px`}}>
                <div className="info-side-panel col-md-2 col-11">
                    <div>
              
                        <div className="v-card v-card--flat v-sheet theme--light">
                            <div className="v-card__title"><IoMdArrowBack onClick={() => navigate(-1)} size={30} /><label
                                className="primary--text px-5 font-weight-bold info-page-title ma-auto text-capitalize">Other
                                topics</label></div>
                        </div>

                        <div className=" v-card v-sheet theme--light">
                            <div>
                                <a href="javascript:void(0)"onClick={() => setPage("about-us")} className={page == "about-us" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}>
                                <span className="v-btn__content">ABOUT US</span>
                              </a>

                            </div>
                            <div>
                                <a href="javascript:void(0)"onClick={() => setPage("privacy-policy")} className={page == "privacy-policy" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}>
                                <span className="v-btn__content">PRIVACY POLICY</span>
                              </a>

                            </div>
                            <div>
                                <a href="javascript:void(0)"
                                onClick={() => setPage("tnc")}
                                className={page == "tnc" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}><span
                                    className="v-btn__content">TERMS &amp; CONDITIONS</span></a>
                            </div>
                            <div>
                                <a href="javascript:void(0)"
                                onClick={() => setPage("rule-regulation")}
                                className={page == "rule-regulation" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}><span
                                    className="v-btn__content">RULE &amp; REGULATIONS</span></a>
                            </div>
                            <div>
                                <a href="javascript:void(0)"
                                onClick={() => setPage("responsible-gaming")}
                                className={page == "responsible-gaming" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}><span
                                    className="v-btn__content">RESPONSIBLE GAMING</span></a>

                            </div>
                            <div>
                                <a href="javascript:void(0)" onClick={() => setPage("faq")} className={page == "faq" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}><span
                                    className="v-btn__content">FAQ</span></a>

                            </div>
                           
                            <div>
                                <a href="javascript:void(0)"
                                onClick={() => setPage("contact-us")}
                                className={page == "contact-us" ? "v-btn theme--light v-size--default active" : "v-btn theme--light v-size--default"}><span
                                    className="v-btn__content">Contact Us</span></a>

                            </div>
                           
                          
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-11">
                    <div>
                        <div className="v-card v-card--flat v-sheet theme--light">
                            <div className="v-card__title">
                                <label className="primary--text px-5 font-weight-bold info-page-title ma-auto">
                                    {page == "faq" ? "FAQ" : page == "about-us" ? "ABOUT US" : page == "contact-us" ? "CONTACT US" : page == "responsible-gaming" ? "RESPONSIBLE GAMING" : page == "privacy-policy" ? "Privacy Policy" : page == "rule-regulation" ? "Rule Regulation"  : page == "tnc" ? "Term & Condition" : ""}
                                </label></div>
                        </div>
                        {page == "faq" ?
                            <div className="info-page-card v-card v-sheet theme--light">
                                <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                    <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                        <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                            <div className="v-expansion-panel-content__wrap">
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Terms &amp; Conditions</p>
                                                    <p>Welcome to 1xbat, Bangladesh #1 Cricket Exchange &amp; Betting Platform!
                                                        Register with us and start betting.
                                                        By opening an account with us, you agree to the following Terms and Conditions.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Account Terms and Conditions</p>
                                                    <p>To use our services, simply register an account and deposit funds.
                                                        Please read our Privacy Policy, Rules and Regulations, Responsible Gambling, and
                                                        FAQs including the provisions with BAU88 Charges that are incorporated into the
                                                        Terms and Conditions.
                                                        Should there be any inconsistencies between existing provisions, the Terms and
                                                        Conditions will prevail.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Your Account</p>
                                                    <p>By opening an account with us, the following representations are required:
                                                        a) You are at least 18 years of age. Gambling for minors is strictly prohibited
                                                        and is punishable by law;
                                                        b) You are capable of taking responsibility of your actions, and can legally
                                                        bind an agreement with us;
                                                        c) You agree to provide accurate information, including but not limited to date
                                                        of birth and country of residence. Furthermore, you agree to inform us of any
                                                        changes in the foregoing information;
                                                        d) You are the person whose details are provided in the registration process;
                                                        e) You are acting as a principal and not as an agent on behalf of a third party;
                                                        f) You are not an undischarged bankrupt or in a voluntary arrangement with your
                                                        creditors;
                                                        g) You are fully responsible in complying with your local, national, federal,
                                                        state, or other laws regarding betting and gambling in connection with account
                                                        registration, placing bets, and using any of our services;
                                                        h) You will provide proper documentation and information upon request to
                                                        identify your status, age, address, country of birth, and other such details
                                                        deemed necessary in connection with opening and maintenance of your account.
                                                        Furthermore, you agree that we verify the credibility of such information.

                                                        It is your responsibility to update the foregoing information of any changes.
                                                        You are responsible for the security and confidentiality of your account. This
                                                        includes your username and password that is used in logging into your account.
                                                        You should frequently change your password and never disclose the same to any
                                                        third party. You undertake to protect your username and password and take full
                                                        responsibility for any risks related to it. If another person manages to access
                                                        your account, you are solely responsible for all their actions whether or not
                                                        their access was authorized by you. Furthermore, you hereby indemnify us and
                                                        hold us harmless against all costs, claims, expenses, and damages arising in
                                                        connection with the use of your account by any third party.
                                                        You will not sell, attempt to sell, or transfer the benefit of your account to
                                                        any third party nor will you acquire or attempt to acquire an account opened and
                                                        registered in the name of a third party.

                                                        Manage your gambling activities fairly, securely, and responsibly.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Deposit and Withdrawal of Funds</p>
                                                    <p>To start betting, you need to deposit funds.
                                                        You undertake that:
                                                        a) All money deposited in your account is untainted with any illegality and does
                                                        not originated from any illegal activity or source;
                                                        b) All payments made into your account are authorized and you will not attempt
                                                        to reverse any payment made into your account or take any action to such extent
                                                        made by as third party in order to avoid any liability.
                                                        c) You accept that all transactions may be checked to prevent money laundering
                                                        and any suspicious transactions will be reported to appropriate authorities.
                                                        d) You agree that you are not entitled to any interest in your deposits and
                                                        acknowledge that you shall not treat 1xbat as a financial institution.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Privacy</p>
                                                    <p>All information sent to and received by us are processed under our Privacy
                                                        Policy.
                                                        You authorize us, at any time, to use any means necessary to verify your
                                                        identity and credentials with any third-party providers of information.
                                                        You agree that, from time to time, we may contact you to inquire about any
                                                        promotional activity should you win a large sum of money, or place an unusually
                                                        successful bet. Should you wish to accept, we may use your name and/or image as
                                                        necessary.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Deposit and Withdrawal of Funds</p>
                                                    <p>To start betting, you need to deposit funds.
                                                        You undertake that:
                                                        a) All money deposited in your account is untainted with any illegality and does
                                                        not originated from any illegal activity or source;
                                                        b) All payments made into your account are authorized and you will not attempt
                                                        to reverse any payment made into your account or take any action to such extent
                                                        made by as third party in order to avoid any liability.
                                                        c) You accept that all transactions may be checked to prevent money laundering
                                                        and any suspicious transactions will be reported to appropriate authorities.
                                                        d) You agree that you are not entitled to any interest in your deposits and
                                                        acknowledge that you shall not treat 1xbat as a financial institution.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Privacy</p>
                                                    <p>All information sent to and received by us are processed under our Privacy
                                                        Policy.
                                                        You authorize us, at any time, to use any means necessary to verify your
                                                        identity and credentials with any third-party providers of information.
                                                        You agree that, from time to time, we may contact you to inquire about any
                                                        promotional activity should you win a large sum of money, or place an unusually
                                                        successful bet. Should you wish to accept, we may use your name and/or image as
                                                        necessary.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Indemnity</p>
                                                    <p>You acknowledge that 1xbat and its affiliates shall hold information with
                                                        respect to your identity, including your name, address, and payment details. You
                                                        agree that we rely on this information in entering into our agreement with you.
                                                        You further agree to hold us free from any falsehood or inaccuracy in the
                                                        information given to us.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Errors and Omissions</p>
                                                    <p>You will inform us as soon as you discover any error in your account and you will
                                                        forfeit any amounts shown in your account resulting from human or technical
                                                        error.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Suspension and Termination</p>
                                                    <p>We may, at our sole discretion, suspend or terminate your account at any time,
                                                        with or without cause, upon notice. You may terminate your account at any given
                                                        time. Just contact our Customer Service should you wish to do so.
                                                        We may block you from betting or using our services if you are found to be
                                                        operating in areas outside our legal jurisdiction.
                                                        We reserve the right to conduct an investigation on any suspicious activities
                                                        with regards to your account. Upon the determination of any investigation, we
                                                        reserve the right to seize some or all of the funds in your account if you are
                                                        found to have violated any of the provisions of the Terms and Conditions. Should
                                                        it be required, we may suspend your account immediately.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Severability</p>
                                                    <p>In the event that any provision of the Terms and Conditions is deemed by any
                                                        competent authority to be unenforceable or invalid, the relevant provision shall
                                                        be modified to be allow its enforceability with respect to the intent of the
                                                        original text. The validity of the remaining provisions shall not be affected by
                                                        such change.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Amendments</p>
                                                    <p>We reserve the right to make any changes to these Terms and Conditions at any
                                                        time. If you do not agree to such changes, you will not be able to continue to
                                                        use our services. </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Betting
                                                        General Conditions
                                                    </p>
                                                    <p>1xbat offers a platform where players may enter into various betting
                                                        transactions in relation to the markets available on our sites.
                                                        Where players bet using our services, 1xbat acts as a facilitator and does not
                                                        act as a counterparty.
                                                        You may enter into betting transactions through our website or through our
                                                        mobile application;

                                                        In these General Conditions when we refer to "the Services" we mean all the
                                                        services listed above (where applicable).
                                                        In these General Conditions references to "1xbat", "we", "us" and/or "our" are
                                                        to whichever 1xbat is providing the Services to you as set out in the Specific
                                                        Conditions applicable to your betting transaction.
                                                        Please read these General Conditions, and the Specific Conditions applicable to
                                                        you and your betting transaction carefully before entering into any betting
                                                        transactions.
                                                        Please note that there are separate Rules and Regulations for each of our
                                                        exchange and products. The rules governing how markets are offered, managed
                                                        and/or settled are not the same for every market on each product. In certain
                                                        circumstances, a bet that is settled as a winner on one product may be settled
                                                        as a loser on the other product (and vice versa). Additionally, different
                                                        settlement rules may apply so that, for example, bets that are a winner on one
                                                        product may be settled as a dead heat or be voided on the other product. Users
                                                        must ensure that they familiarize themselves with the relevant rules that apply
                                                        to the bets that they place on 1xbat.
                                                        Should there be any inconsistencies between the documents mentioned, this
                                                        document, and the relevant Specific Conditions, the Specific Conditions will
                                                        prevail over this document, and this document will prevail over the documents
                                                        listed above.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Conditions on Using 1xbat</p>
                                                    <p>info.info_tnc_lc_desc</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Use of Our Services</p>
                                                    <p>You are responsible for any money lost resulting from losing bets. You accept
                                                        that under no circumstances will any amounts lost by you under any bet be
                                                        recoverable from us.
                                                        It is your responsibility to verify that you are permitted to bet with us
                                                        according to the jurisdiction of your domicile or habitual residence and/or in
                                                        which you are located and you agree that you will not bet with us while you are
                                                        located in a jurisdiction which prohibits you from betting with us.
                                                        You undertake to use our services for legitimate betting purposes only. In
                                                        particular you undertake not to
                                                        a) engage in any activity which has the purpose or effect of causing damage to
                                                        or in any way hindering our business operations or generating or increasing a
                                                        liability on us, including without limitation any tax, levy or duty collecting
                                                        authority;
                                                        b) bet on, or manipulate any individual Market in a manner which we believe has
                                                        the purpose or effect of adversely affecting the integrity of the Exchange or
                                                        any Market;
                                                        c) transmit information that assists in the placing of bets or wagers on any
                                                        sporting event or contest from any territory where it is illegal to do so; and/
                                                        or
                                                        d) procure or otherwise arrange, whether directly or indirectly, for a third
                                                        party to transmit information that assists in the placing of bets or wagers on
                                                        any sporting event or contest from any territory where it is illegal to do so.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        General Conduct</p>
                                                    <p>You will not attempt to use our services to deliberately transfer money from one
                                                        1xbat account to another by attempting to match bets on a market with another
                                                        account through collusion.
                                                        You will inform us as soon as you become aware of any errors betting
                                                        calculations. We reserve the right to declare null and void any erroneous bets.
                                                        You will not or interfere in any way with the IP address of the computer you are
                                                        using to access the site or otherwise take steps to prevent us from correctly
                                                        identifying the actual IP address of the computer you are using whilst accessing
                                                        the site.
                                                        You agree it is your responsibility to account for any tax or duty imposed on
                                                        you as a result of any of your betting transactions.
                                                        You will not make offensive comments, use offensive or pornographic material or
                                                        make potentially defamatory or inflammatory remarks in connection with the
                                                        forums or any other part of our site including within your own username or other
                                                        information contributed to our site.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Privacy</p>
                                                    <p>Any information you send to us will be processed in accordance with our Privacy
                                                        Policy.
                                                        You authorize us, at any time, to use any means necessary to verify your
                                                        identity and credentials with any third-party providers of information.
                                                        We reserve the right to record all communications made to and from 1xbat and to
                                                        monitor all information relating to betting activities
                                                        You agree to indemnify us and hold us harmless in respect of the release of any
                                                        personal (or other) data pursuant to this Agreement.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Conditions Relating to Betting</p>
                                                    <p>You are responsible for understanding the contents of our site and the operation
                                                        of the Services; we reserve the right to change the format of the Services in
                                                        order to enhance such
                                                        We may determine when the Markets are open for betting and may close the Markets
                                                        at any time in our absolute discretion. We will at all times use reasonable
                                                        endeavors to settle Markets as quickly as possible, we give no assurances
                                                        however, as to the timeframe in which Markets will be settled.
                                                        You are entirely responsible for the information you give us concerning the
                                                        offer that you wish to place. We will not be liable for incorrect entries made
                                                        by you, including data input errors with respect to the odds, price or stake on
                                                        offer, or incorrect bet requests communicated by you to our operators. If our
                                                        operators repeat back your bet request incorrectly, it is your responsibility to
                                                        correct the bet request and inform us of the error. Once you have confirmed the
                                                        bet request and your bet is matched, you will be liable for that bet if it
                                                        loses.
                                                        1xbat may, in its sole and absolute discretion, decide to suspend betting on a
                                                        market at any time. In the interests of maintaining integrity and fairness in
                                                        the Markets, 1xbat may also void certain bets in a market or void a whole
                                                        Market in its entirety.

                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Cancellation, Termination, Suspension, and Breach</p>
                                                    <p>We may restrict your access to 1xbat, suspend or terminate your account,
                                                        withdraw your offers for bets, void any bets outstanding to your account, cancel
                                                        any unmatched bets or cancel and void any outstanding or matched bets in our
                                                        absolute discretion without cause at any time if:
                                                        there is a technological failure;
                                                        a) we suspect that you are engaging in illegal or fraudulent activity;
                                                        b) we suspect that you have (or may have) breached any part of this Agreement;
                                                        c) we suspect that you have breached the terms of a 1xbat promotion;
                                                        d) we suspect that you are acting in collusion with other players to take unfair
                                                        advantage of a bet/promotion and/or in the course of participating in a
                                                        bet/promotion, you have become able to guarantee wins and/or profits with no or
                                                        only minimal risk;
                                                        e) we suspect that your account's "public market data" usage ('public market
                                                        data' includes market prices, traded volumes and market depth) could represent
                                                        business usage (˜'business usage' includes any use by a betting operator or any
                                                        use by an individual or organization supplying data or services to a betting
                                                        operator)
                                                        f) you are prohibited from entering into a bet by any term of your contract of
                                                        employment or any rule of a sport governing body or other professional body
                                                        which applies to you; or
                                                        g) you place a bet on any sporting match or event in which you take part (or in
                                                        which a team of which you are a member, or in respect of which you are employed,
                                                        takes part).
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Underage Gambling</p>
                                                    <p>It is illegal for anyone under the age of 18 to register or gamble on 1xbat. If
                                                        we identify that you are under 18 or were under 18 when you entered into any
                                                        transactions on our site:
                                                        a) We will immediately block you from entering into any betting transactions or
                                                        withdrawing funds, or using your account;
                                                        b) We will investigate the claim that you were under age, including whether you
                                                        have been betting as an agent for or on behalf of another person;
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Cancellation of an Offer for a Bet</p>
                                                    <p>Your cancellation of an unmatched bet becomes effective once we send confirmation
                                                        of the cancellation. In the event that your cancellation request is not received
                                                        and processed in good time, you acknowledge that your offer may remain
                                                        outstanding and available for acceptance.
                                                        You may not be able to cancel your offer if your bet has been partially or
                                                        wholly accepted during the interval between your submitting your cancellation
                                                        request and the confirmation of said request. In this case, the original offer,
                                                        or part thereof, will be valid and the bet will be concluded.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Minimum and Maximum Bet Stakes</p>
                                                    <p>The minimum and maximum bet depends on the product and/or type of market you are
                                                        betting on. The amounts are subject to change and may differ depending on the
                                                        platforms used. You are not allowed to bet below the minimum bet size threshold
                                                        and doing so may result to account closure
                                                        Your betting limit is represented by your “Available to Bet” balance and
                                                        “Exposure Limit” as shown in your account, whichever is lower. In the event that
                                                        we process an offer for a bet or the acceptance of a bet in an amount outside
                                                        the applicable thresholds, such bet will nonetheless stand.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Bet Settlement</p>
                                                    <p>Subject to these General Conditions and any applicable Specific Conditions, we
                                                        will settle markets according to our 1xbat Rules and Regulations, Sportsbook
                                                        Rules and Regulations. We reserve the right to reverse or amend settlement in
                                                        the event of a market being settled incorrectly. This may lead to amendments
                                                        being made to your account to reflect changes in market settlement and if there
                                                        are insufficient funds in your account we may demand that you put funds into
                                                        your account to address the outstanding balance. We will notify you if your
                                                        account goes into a negative balance and, if following the notification, you
                                                        either:
                                                        a) do not put funds in your account within the reasonable time specified in our
                                                        notice to you, or
                                                        b) you fail to make the relevant payments into your account in accordance with
                                                        any repayment plan as agreed between you and 1xbat; or
                                                        c) you indicate that you have no intention of putting funds in your account to
                                                        address the outstanding balance, then we reserve the right to suspend your
                                                        account and/or take appropriate legal action to seek to recover the debt, in
                                                        full, from you.
                                                        Any amount of winnings or losses relating to your bets on a Market will be
                                                        rounded to the nearest two decimal places.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Matters beyond our reasonable control</p>
                                                    <p>Without prejudice to our obligations contained within the various laws and
                                                        regulations of the jurisdictions where we are licensed, we are not liable for
                                                        any loss or damage that you may suffer because of any: act of God; power cut;
                                                        trade or labor dispute; act, failure or omission of any government or authority;
                                                        obstruction or failure of telecommunication services; or any other delay or
                                                        failure caused by a third party or otherwise outside of our control. In such an
                                                        event, we reserve the right to cancel or suspend our services without incurring
                                                        any liability.

                                                        We are not liable for the failure of any equipment or software howsoever caused,
                                                        wherever located or administered, or whether under our direct control or not,
                                                        that may prevent the operation of the Services, impede the placing of offers for
                                                        bets or the matching of bets, or prevent you from being able to contact us.
                                                        We will not be liable for any failure to perform by a third party to our
                                                        Agreement.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Indemnity</p>
                                                    <p>You agree to indemnify and hold us and our associated companies, affiliates,
                                                        officers, directors, agents and employees harmless from any liabilities, claims,
                                                        losses or demands made by any third party arising out of your breach of this
                                                        Agreement (including documents incorporated by reference) or out of your
                                                        violation of any law or the rights of any third party.
                                                        You acknowledge that 1xbat and its affiliates will hold information with
                                                        respect to your identity, including but not limited to your name, address and
                                                        payment details. You agree that we rely on this information in entering into
                                                        this Agreement and you agree to hold us harmless against any falsehood or
                                                        inaccuracy contained in the information you provide us.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Limitation of Liability</p>
                                                    <p>We exclude all representations and warranties as to the satisfactory quality
                                                        and/or fitness for its intended purpose and/or accuracy and completeness of the
                                                        Services.
                                                        We will not be liable to you for any loss that you may incur as a result of
                                                        misuse of your password, or in the case of the Telephone Service, of your
                                                        telephone access number, and we accept no liability resulting from its
                                                        unauthorized use, whether fraudulent or otherwise.
                                                        In no event will we, or any of our suppliers, accept any liability however
                                                        arising for any losses you may incur as a result of using the Services. Under no
                                                        circumstances will our liability under this Agreement or for breach of contract,
                                                        tort, equity or otherwise exceed your exposure limit as specified in your
                                                        account. Under no circumstances will we be liable for any indirect, special or
                                                        consequential damages, loss of profits (direct or indirect) or the benefit of
                                                        any bet arising from breach of contract, negligence, equitable duty (including
                                                        for the avoidance of doubt in relation to any bet(s) or Market(s) voided by us)
                                                        or other liability even if we had been advised of or known (or should have
                                                        known) of the possibility of such damages or loss.
                                                        Nothing in this Agreement excludes our liability with respect to death and
                                                        personal injury resulting from our negligence, or that of our employees, agents
                                                        or subcontractors.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Intellectual property</p>
                                                    <p>1xbat owns all copyright and related rights in and to our site, including,
                                                        without limitation, rights in databases and all rights in any price data and
                                                        related content on our site except for certain third-party rights (referred to
                                                        below);
                                                        a) all trade mark rights, whether registered or unregistered, in and to 1xbat
                                                        and the 1xbat logo; and
                                                        b) the domain name www.crickex.com which is our uniform resource locator
                                                        ("URL").

                                                        The following use of price data or any other data or content from our site or
                                                        via the API or any other 1xbat data from any other source is strictly
                                                        prohibited without our prior consent:
                                                        a) commercial use by any person; and/or
                                                        b) any use for any purpose by a competitor of the 1xbat group of companies, or
                                                        an employee, contractor or agent of any such competitor ("Restricted Person"),
                                                        provided always that Restricted Persons may place bets.

                                                        Screen scraping, web scraping or any other automated or manual collection of
                                                        1xbat Data, for commercial or personal use, by any person is expressly
                                                        prohibited.
                                                        Any unauthorized use of any of the above rights may result in prosecution or
                                                        other action being taken against you.
                                                        You hereby assign to 1xbat absolutely, any and all copyright and other
                                                        intellectual property rights throughout the world in all media whether now known
                                                        or hereafter developed, for the full period of copyright, including by way of
                                                        present assignment of future copyright and all other rights whatsoever, in any
                                                        offers for bets or bets placed by you on our site.
                                                        Any data licensed to 1xbat from third parties is provided for use on our site
                                                        only and may not be used for any commercial purposes without the consent of such
                                                        third parties.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Information Services</p>
                                                    <p>1xbat from time to time may provide you with access to various information and
                                                        content. This may include form data, results, video streaming, upcoming events,
                                                        the times and dates of events, current scores, time elapsed, and other
                                                        information relating to a sporting event. Such information may be supplied:
                                                        a) via our site (including micro-sites and as part of any runner data and/or
                                                        results service), emails or any other means of communication; and/or
                                                        b) via links to third party websites.

                                                        Such information is described in these Terms and Conditions collectively as the
                                                        "Information".
                                                        The Information is provided by 1xbat or is sourced from a third party. Although
                                                        some Information may be described as "live", or it may be implied that such
                                                        Information is "live", you should be aware that this data may be subject to a
                                                        time delay. The Information may also be inaccurate. You should also be aware
                                                        that other 1xbat users may have access to pictures and/or data that is faster
                                                        and/or more accurate than the Information. The Information is supplied "as is"
                                                        and is provided for guidance only. To the fullest extent permitted by law,
                                                        1xbat makes no representations or warranties of any kind, including but not
                                                        limited to, the accuracy, quality, completeness or timeliness of the
                                                        Information.
                                                        If you rely on the Information to place bets, you do so entirely at your own
                                                        risk. 1xbat shall not be liable for any action taken or not taken by you as a
                                                        result of your reliance on the Information or for any loss or damage (direct or
                                                        indirect) suffered by you as a result of your use of the Information.
                                                        Any links to third party websites does not constitute an endorsement by 1xbat
                                                        of any products or services available on such websites. You use such websites at
                                                        your own risk and 1xbat takes no responsibility for the content on, or use of,
                                                        such websites.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Assignment</p>
                                                    <p>You may not assign any part of this Agreement without 1xbat' written consent but
                                                        1xbat may assign any or all of this Agreement to any third party at any time.
                                                        In the event of an assignment by 1xbat, we will notify of this change and, if
                                                        you do not agree to this, then you will not be able to continue to use our
                                                        services, but you shall always be able to withdraw your funds subject to these
                                                        Terms and Conditions.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Severability</p>
                                                    <p>In the event that any provision of this Agreement is deemed by any competent
                                                        authority to be unenforceable or invalid, the relevant provision shall be
                                                        modified to allow it to be enforced in line with the intention of the original
                                                        text to the fullest extent permitted by applicable law. The validity and
                                                        enforceability of the remaining provisions of this Agreement shall not be
                                                        affected.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Dispute resolution</p>
                                                    <p>In the event of a dispute arising between us and you, we each agree to follow the
                                                        procedure set out in our Dispute Resolution policy as amended from time to time.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        Amendments</p>
                                                    <p>We reserve the right to make immaterial changes to our site and all guides and
                                                        policies included on our site, including this Agreement at any time.</p>
                                                </div>
                                                <div>
                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                        No waiver</p>
                                                    <p>No failure or delay by a party to exercise any of its rights under this Agreement
                                                        shall operate as a waiver thereof and no single or partial exercise of any such
                                                        right shall prevent any other or further exercise of that or any other right.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : page == "about-us" ?
                                <div className="info-page-card v-card v-sheet theme--light">
                                    <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                        <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                            <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                                <div className="v-expansion-panel-content__wrap">
                                                    <div>
                                                        <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">About us</p>
                                                        <p>1xbat is an online gambling company, offering a wide range of betting and casino options.
                                                            Founded in 2021, 1xbat began as a cricket exchange platform serving the South Asian
                                                            market.<br />Our goal is very simple: to provide high-quality entertainment and gaming to our
                                                            customer base.</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Sports
                                                            Betting</p>
                                                        <p>1xbat provides extensive sports betting opportunities. Users can place bets on various
                                                            sports events including IPL, World Cup, Big Bash League, CPL, T20, Test Cricket, and ICC
                                                            matches.</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Casino Games
                                                        </p>
                                                        <p>1xbat offers a range of casino games. Users can enjoy games like roulette, poker, baccarat,
                                                            as well as all the latest live casino trends from industry leading providers like Pragmatic
                                                            Play or Evolution Gaming.</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Mobile
                                                            Application</p>
                                                        <p>1xbat offers a mobile application that replicates all the gambling features found on the
                                                            official site. The app provides a more streamlined and expeditious interface for users.</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Security and
                                                            Customer Service</p>
                                                        <p>1xbat prioritizes security and safety. It uses advanced encryption technology to keep user
                                                            information secure¹. The platform also offers 24/7 customer service.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : page == "contact-us" ?
                                    <div className="info-page-card v-card v-sheet theme--light">
                                        <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                            <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                                <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                                    <div className="v-expansion-panel-content__wrap">
                                                        <div>
                                                            <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Customer
                                                                Service</p>
                                                            <p>CS@1xbat.bet</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Marketing
                                                            </p>
                                                            <p>Marketing@1xbat.bet</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Affiliate
                                                            </p>
                                                            <p>Affiliate@1xbat.bet</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : page == "responsible-gaming" ?
                                        <div className="info-page-card v-card v-sheet theme--light">
                                            <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                                <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                                    <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                                        <div className="v-expansion-panel-content__wrap">
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Play
                                                                    responsibly</p>
                                                                <p>Playing at 1xbat should be something positive and entertaining, whilst also thrilling and
                                                                    fun. Sometimes it is hard to control how much we want to play in relation to how much we can
                                                                    afford to lose. In this section you’ll find ways to help make sure your gambling is within
                                                                    controllable limits, as well as show you places to turn to if you feel you might require
                                                                    help.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">I think I
                                                                    might be playing too much</p>
                                                                <p>Below we have listed statements taken from a renowned psychological research paper. If you
                                                                    find that one or more of these statements are true for you, you might have a gambling
                                                                    problem and we recommend that you seek professional help and see how 1xbat can help you
                                                                    deal with this problem in sections 3 and 4 below.
                                                                    You’ve repeated unsuccessful efforts to control, cut back, or stop gambling.
                                                                    You’re restless or irritable when attempting to cut down or stop gambling.
                                                                    You gamble as a way of escaping from problems or of relieving a dysphoric mood.
                                                                    You often return another day after losing money gambling, to get even (“chasing” one’s
                                                                    losses).
                                                                    You lie to family members, therapist, or others to conceal the extent of involvement with
                                                                    gambling.
                                                                    You’ve committed illegal acts such as forgery, fraud, theft, or embezzlement to finance
                                                                    gambling.
                                                                    You’ve jeopardized or lost a significant relationship, job, or educational or career
                                                                    opportunity because of gambling.
                                                                    You rely on others to provide money to relieve a desperate financial situation caused by
                                                                    gambling.
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">I think that
                                                                    a friend, or someone in my family plays too much</p>
                                                                <p>Below there is a list of warning signals and signs. Does this remind you of someone you know?
                                                                    At “Read more and contact” you will find more information and contact details to
                                                                    professionals who work with gambling addiction.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Time</p>
                                                                <p>More and more time is used to gamble and plan around playing. The person is preoccupied more
                                                                    often and prioritises gambling over things that used to be more important in the person’s
                                                                    life.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Economy</p>
                                                                <p>Money disappears from accounts, the person is borrowing money more often without being able
                                                                    to pay back, the person can have money problems or trouble paying the bills, even with a
                                                                    steady income.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Mood</p>
                                                                <p>The person is more worried than usual, more easily irritated and shows several signs of
                                                                    depression and anxiety.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Behaviour
                                                                </p>
                                                                <p>It is not unusual that the person is becoming isolated from friends and relatives and avoids
                                                                    social activities.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Set your
                                                                    limits and play responsibly</p>
                                                                <p>If you are concerned that sometimes you may play for more than you plan, we can offer you
                                                                    some helpful limits to set before you start playing. When setting your limit it will go into
                                                                    effect right away and start taking into account your activity from the time you set it. For
                                                                    example, should you set a weekly limit on Wednesday, it will reset midnight to Monday and
                                                                    then continue to run from Monday to Sunday. It takes 24 hours to revoke or ease up a limit.
                                                                    But if you decide to make a limit tighter, then the change is instant. You can activate the
                                                                    limit that suits you the best via your Play Ok settings here.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Spending
                                                                    budget</p>
                                                                <p>This will limit the amount you can spend during a certain period. You will be able to make
                                                                    deposits up to the amount you have chosen and in addition to this, you can deposit an amount
                                                                    equal to any withdrawals made during this period. If you set a budget of €50 weekly and
                                                                    withdraw €100 that same week, you will be able to deposit a total of €150.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit
                                                                    limit</p>
                                                                <p>This will limit the amount you can deposit during a certain period. Once you have reached
                                                                    this sum you will not be able to make any new deposits until your limit is reset.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Wager limit
                                                                </p>
                                                                <p>This will limit the total amount you can bet during a certain period. When you have reached
                                                                    this sum, you will not be able to place any new bets until your limit has reset.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Loss limit
                                                                </p>
                                                                <p>This will limit the possible amount of net losses you can make based on bets minus wins
                                                                    during a certain period. When you have reached this sum, you will not be able to place any
                                                                    new bets until your limit is reset.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Logged in
                                                                    time limit</p>
                                                                <p>Limit how many hours you can be logged in to your account daily. Once you reach your limit,
                                                                    you will be automatically logged out and any gameplay is paused or completed in the
                                                                    background. ProTip: Make sure to always log out of your account when you finish your
                                                                    session.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Login time
                                                                    block</p>
                                                                <p>Set a specific time block covering all days of the week during which you will not be able to
                                                                    log in to your account. The start- and end time of this limit will calculate according to
                                                                    the time zone you are in when activating it.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Take a break
                                                                    or stop playing</p>
                                                                <p>Sometimes you might need a break from playing and you can suspend your account for a period
                                                                    of your choice. If you want a cool off period you can go for a shorter break. If you are
                                                                    concerned that you have been playing too much we recommend that you instead self-exclude
                                                                    your account for a minimum period of 6 months. When gambling has become a problem for you,
                                                                    however, an indefinite exclusion from online gambling is the right choice for you. You can
                                                                    suspend your account via your Play Ok settings here.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Take a break
                                                                </p>
                                                                <p>You can take a break from 1xbat for up to 6 months. Once your break is over, your account
                                                                    will be automatically reopened. You will be able to make changes to the end date at a later
                                                                    time if you so choose. If you end it early, a 24 hour cool off period will apply before you
                                                                    can log in again. Speak with us in our chat if you need any advice. You will not receive any
                                                                    emails or texts from us during this time.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Self
                                                                    exclusion</p>
                                                                <p>If you feel that you have been playing too much or are concerned that your gambling is
                                                                    becoming a problem, we recommend that you exclude your account for at least 6 months. You
                                                                    will not be able to return during this time and will not receive any emails or texts from
                                                                    us. Once your exclusion has expired your account will automatically reopen. Contact us in
                                                                    our live chat to let us know if you want to extend your exclusion. If gambling has become a
                                                                    problem for you, you should close your account indefinitely instead and seek professional
                                                                    help.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Professional
                                                                    support and helplines</p>
                                                                <p></p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Gambling
                                                                    Therapy</p>
                                                                <p>Gambling Therapy is a global service offering free practical advice and emotional support to
                                                                    anyone affected by problem gambling. Gambling Therapy also offers a free app providing a
                                                                    collection of tools and information to help you identify and overcome problem gambling.

                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Gordon Moody
                                                                    Association</p>
                                                                <p>Gordon Moody Association provides advice, education and high quality innovative therapeutic
                                                                    support to problem gamblers and those affected by problem gambling, through residential,
                                                                    online and outreach services.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Gamblers
                                                                    Anonymous</p>
                                                                <p>Gamblers Anonymous is a fellowship of men and women who share their experience, strength and
                                                                    hope with each other that they may solve their common problem and help others to do the
                                                                    same.</p>
                                                            </div>
                                                            <div>
                                                                <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Gamban</p>
                                                                <p>GamBan offers efficient software that prevents you from accessing gambling sites and online
                                                                    casinos. It’s simple to use and helps you by only blocking gambling sites and applications.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : page == "tnc" ?
                                            <div className="info-page-card v-card v-sheet theme--light">
                                                <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                                    <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                                        <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                                            <div className="v-expansion-panel-content__wrap">
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Terms &amp;
                                                                        Conditions</p>
                                                                    <p>Welcome to 1xbat, Bangladesh #1 Cricket Exchange &amp; Betting Platform!
                                                                        Register with us and start betting.
                                                                        By opening an account with us, you agree to the following Terms and Conditions.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Account
                                                                        Terms and Conditions</p>
                                                                    <p>To use our services, simply register an account and deposit funds.
                                                                        Please read our Privacy Policy, Rules and Regulations, Responsible Gambling, and FAQs
                                                                        including the provisions with BAU88 Charges that are incorporated into the Terms and
                                                                        Conditions.
                                                                        Should there be any inconsistencies between existing provisions, the Terms and Conditions
                                                                        will prevail.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Your Account
                                                                    </p>
                                                                    <p>By opening an account with us, the following representations are required:
                                                                        a) You are at least 18 years of age. Gambling for minors is strictly prohibited and is
                                                                        punishable by law;
                                                                        b) You are capable of taking responsibility of your actions, and can legally bind an
                                                                        agreement with us;
                                                                        c) You agree to provide accurate information, including but not limited to date of birth and
                                                                        country of residence. Furthermore, you agree to inform us of any changes in the foregoing
                                                                        information;
                                                                        d) You are the person whose details are provided in the registration process;
                                                                        e) You are acting as a principal and not as an agent on behalf of a third party;
                                                                        f) You are not an undischarged bankrupt or in a voluntary arrangement with your creditors;
                                                                        g) You are fully responsible in complying with your local, national, federal, state, or
                                                                        other laws regarding betting and gambling in connection with account registration, placing
                                                                        bets, and using any of our services;
                                                                        h) You will provide proper documentation and information upon request to identify your
                                                                        status, age, address, country of birth, and other such details deemed necessary in
                                                                        connection with opening and maintenance of your account. Furthermore, you agree that we
                                                                        verify the credibility of such information.

                                                                        It is your responsibility to update the foregoing information of any changes.
                                                                        You are responsible for the security and confidentiality of your account. This includes your
                                                                        username and password that is used in logging into your account.
                                                                        You should frequently change your password and never disclose the same to any third party.
                                                                        You undertake to protect your username and password and take full responsibility for any
                                                                        risks related to it. If another person manages to access your account, you are solely
                                                                        responsible for all their actions whether or not their access was authorized by you.
                                                                        Furthermore, you hereby indemnify us and hold us harmless against all costs, claims,
                                                                        expenses, and damages arising in connection with the use of your account by any third party.
                                                                        You will not sell, attempt to sell, or transfer the benefit of your account to any third
                                                                        party nor will you acquire or attempt to acquire an account opened and registered in the
                                                                        name of a third party.

                                                                        Manage your gambling activities fairly, securely, and responsibly.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit and
                                                                        Withdrawal of Funds</p>
                                                                    <p>To start betting, you need to deposit funds.
                                                                        You undertake that:
                                                                        a) All money deposited in your account is untainted with any illegality and does not
                                                                        originated from any illegal activity or source;
                                                                        b) All payments made into your account are authorized and you will not attempt to reverse
                                                                        any payment made into your account or take any action to such extent made by as third party
                                                                        in order to avoid any liability.
                                                                        c) You accept that all transactions may be checked to prevent money laundering and any
                                                                        suspicious transactions will be reported to appropriate authorities.
                                                                        d) You agree that you are not entitled to any interest in your deposits and acknowledge that
                                                                        you shall not treat 1xbat as a financial institution.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                    <p>All information sent to and received by us are processed under our Privacy Policy.
                                                                        You authorize us, at any time, to use any means necessary to verify your identity and
                                                                        credentials with any third-party providers of information.
                                                                        You agree that, from time to time, we may contact you to inquire about any promotional
                                                                        activity should you win a large sum of money, or place an unusually successful bet. Should
                                                                        you wish to accept, we may use your name and/or image as necessary.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit and
                                                                        Withdrawal of Funds</p>
                                                                    <p>To start betting, you need to deposit funds.
                                                                        You undertake that:
                                                                        a) All money deposited in your account is untainted with any illegality and does not
                                                                        originated from any illegal activity or source;
                                                                        b) All payments made into your account are authorized and you will not attempt to reverse
                                                                        any payment made into your account or take any action to such extent made by as third party
                                                                        in order to avoid any liability.
                                                                        c) You accept that all transactions may be checked to prevent money laundering and any
                                                                        suspicious transactions will be reported to appropriate authorities.
                                                                        d) You agree that you are not entitled to any interest in your deposits and acknowledge that
                                                                        you shall not treat 1xbat as a financial institution.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                    <p>All information sent to and received by us are processed under our Privacy Policy.
                                                                        You authorize us, at any time, to use any means necessary to verify your identity and
                                                                        credentials with any third-party providers of information.
                                                                        You agree that, from time to time, we may contact you to inquire about any promotional
                                                                        activity should you win a large sum of money, or place an unusually successful bet. Should
                                                                        you wish to accept, we may use your name and/or image as necessary.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Indemnity
                                                                    </p>
                                                                    <p>You acknowledge that 1xbat and its affiliates shall hold information with respect to your
                                                                        identity, including your name, address, and payment details. You agree that we rely on this
                                                                        information in entering into our agreement with you. You further agree to hold us free from
                                                                        any falsehood or inaccuracy in the information given to us.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Errors and
                                                                        Omissions</p>
                                                                    <p>You will inform us as soon as you discover any error in your account and you will forfeit any
                                                                        amounts shown in your account resulting from human or technical error.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Suspension
                                                                        and Termination</p>
                                                                    <p>We may, at our sole discretion, suspend or terminate your account at any time, with or
                                                                        without cause, upon notice. You may terminate your account at any given time. Just contact
                                                                        our Customer Service should you wish to do so.
                                                                        We may block you from betting or using our services if you are found to be operating in
                                                                        areas outside our legal jurisdiction.
                                                                        We reserve the right to conduct an investigation on any suspicious activities with regards
                                                                        to your account. Upon the determination of any investigation, we reserve the right to seize
                                                                        some or all of the funds in your account if you are found to have violated any of the
                                                                        provisions of the Terms and Conditions. Should it be required, we may suspend your account
                                                                        immediately.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Severability
                                                                    </p>
                                                                    <p>In the event that any provision of the Terms and Conditions is deemed by any competent
                                                                        authority to be unenforceable or invalid, the relevant provision shall be modified to be
                                                                        allow its enforceability with respect to the intent of the original text. The validity of
                                                                        the remaining provisions shall not be affected by such change.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Amendments
                                                                    </p>
                                                                    <p>We reserve the right to make any changes to these Terms and Conditions at any time. If you do
                                                                        not agree to such changes, you will not be able to continue to use our services. </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Betting
                                                                        General Conditions
                                                                    </p>
                                                                    <p>1xbat offers a platform where players may enter into various betting transactions in
                                                                        relation to the markets available on our sites.
                                                                        Where players bet using our services, 1xbat acts as a facilitator and does not act as a
                                                                        counterparty.
                                                                        You may enter into betting transactions through our website or through our mobile
                                                                        application;

                                                                        In these General Conditions when we refer to "the Services" we mean all the services listed
                                                                        above (where applicable).
                                                                        In these General Conditions references to "1xbat", "we", "us" and/or "our" are to whichever
                                                                        1xbat is providing the Services to you as set out in the Specific Conditions applicable to
                                                                        your betting transaction.
                                                                        Please read these General Conditions, and the Specific Conditions applicable to you and your
                                                                        betting transaction carefully before entering into any betting transactions.
                                                                        Please note that there are separate Rules and Regulations for each of our exchange and
                                                                        products. The rules governing how markets are offered, managed and/or settled are not the
                                                                        same for every market on each product. In certain circumstances, a bet that is settled as a
                                                                        winner on one product may be settled as a loser on the other product (and vice versa).
                                                                        Additionally, different settlement rules may apply so that, for example, bets that are a
                                                                        winner on one product may be settled as a dead heat or be voided on the other product. Users
                                                                        must ensure that they familiarize themselves with the relevant rules that apply to the bets
                                                                        that they place on 1xbat.
                                                                        Should there be any inconsistencies between the documents mentioned, this document, and the
                                                                        relevant Specific Conditions, the Specific Conditions will prevail over this document, and
                                                                        this document will prevail over the documents listed above.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Conditions
                                                                        on Using 1xbat</p>
                                                                    <p>info.info_tnc_lc_desc</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Use of Our
                                                                        Services</p>
                                                                    <p>You are responsible for any money lost resulting from losing bets. You accept that under no
                                                                        circumstances will any amounts lost by you under any bet be recoverable from us.
                                                                        It is your responsibility to verify that you are permitted to bet with us according to the
                                                                        jurisdiction of your domicile or habitual residence and/or in which you are located and you
                                                                        agree that you will not bet with us while you are located in a jurisdiction which prohibits
                                                                        you from betting with us.
                                                                        You undertake to use our services for legitimate betting purposes only. In particular you
                                                                        undertake not to
                                                                        a) engage in any activity which has the purpose or effect of causing damage to or in any way
                                                                        hindering our business operations or generating or increasing a liability on us, including
                                                                        without limitation any tax, levy or duty collecting authority;
                                                                        b) bet on, or manipulate any individual Market in a manner which we believe has the purpose
                                                                        or effect of adversely affecting the integrity of the Exchange or any Market;
                                                                        c) transmit information that assists in the placing of bets or wagers on any sporting event
                                                                        or contest from any territory where it is illegal to do so; and/ or
                                                                        d) procure or otherwise arrange, whether directly or indirectly, for a third party to
                                                                        transmit information that assists in the placing of bets or wagers on any sporting event or
                                                                        contest from any territory where it is illegal to do so.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">General
                                                                        Conduct</p>
                                                                    <p>You will not attempt to use our services to deliberately transfer money from one 1xbat
                                                                        account to another by attempting to match bets on a market with another account through
                                                                        collusion.
                                                                        You will inform us as soon as you become aware of any errors betting calculations. We
                                                                        reserve the right to declare null and void any erroneous bets.
                                                                        You will not or interfere in any way with the IP address of the computer you are using to
                                                                        access the site or otherwise take steps to prevent us from correctly identifying the actual
                                                                        IP address of the computer you are using whilst accessing the site.
                                                                        You agree it is your responsibility to account for any tax or duty imposed on you as a
                                                                        result of any of your betting transactions.
                                                                        You will not make offensive comments, use offensive or pornographic material or make
                                                                        potentially defamatory or inflammatory remarks in connection with the forums or any other
                                                                        part of our site including within your own username or other information contributed to our
                                                                        site.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                    <p>Any information you send to us will be processed in accordance with our Privacy Policy.
                                                                        You authorize us, at any time, to use any means necessary to verify your identity and
                                                                        credentials with any third-party providers of information.
                                                                        We reserve the right to record all communications made to and from 1xbat and to monitor all
                                                                        information relating to betting activities
                                                                        You agree to indemnify us and hold us harmless in respect of the release of any personal (or
                                                                        other) data pursuant to this Agreement.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Conditions
                                                                        Relating to Betting</p>
                                                                    <p>You are responsible for understanding the contents of our site and the operation of the
                                                                        Services; we reserve the right to change the format of the Services in order to enhance such
                                                                        We may determine when the Markets are open for betting and may close the Markets at any time
                                                                        in our absolute discretion. We will at all times use reasonable endeavors to settle Markets
                                                                        as quickly as possible, we give no assurances however, as to the timeframe in which Markets
                                                                        will be settled.
                                                                        You are entirely responsible for the information you give us concerning the offer that you
                                                                        wish to place. We will not be liable for incorrect entries made by you, including data input
                                                                        errors with respect to the odds, price or stake on offer, or incorrect bet requests
                                                                        communicated by you to our operators. If our operators repeat back your bet request
                                                                        incorrectly, it is your responsibility to correct the bet request and inform us of the
                                                                        error. Once you have confirmed the bet request and your bet is matched, you will be liable
                                                                        for that bet if it loses.
                                                                        1xbat may, in its sole and absolute discretion, decide to suspend betting on a market at
                                                                        any time. In the interests of maintaining integrity and fairness in the Markets, 1xbat may
                                                                        also void certain bets in a market or void a whole Market in its entirety.

                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                                        Cancellation, Termination, Suspension, and Breach</p>
                                                                    <p>We may restrict your access to 1xbat, suspend or terminate your account, withdraw your
                                                                        offers for bets, void any bets outstanding to your account, cancel any unmatched bets or
                                                                        cancel and void any outstanding or matched bets in our absolute discretion without cause at
                                                                        any time if:
                                                                        there is a technological failure;
                                                                        a) we suspect that you are engaging in illegal or fraudulent activity;
                                                                        b) we suspect that you have (or may have) breached any part of this Agreement;
                                                                        c) we suspect that you have breached the terms of a 1xbat promotion;
                                                                        d) we suspect that you are acting in collusion with other players to take unfair advantage
                                                                        of a bet/promotion and/or in the course of participating in a bet/promotion, you have become
                                                                        able to guarantee wins and/or profits with no or only minimal risk;
                                                                        e) we suspect that your account's "public market data" usage ('public market data' includes
                                                                        market prices, traded volumes and market depth) could represent business usage (˜'business
                                                                        usage' includes any use by a betting operator or any use by an individual or organization
                                                                        supplying data or services to a betting operator)
                                                                        f) you are prohibited from entering into a bet by any term of your contract of employment or
                                                                        any rule of a sport governing body or other professional body which applies to you; or
                                                                        g) you place a bet on any sporting match or event in which you take part (or in which a team
                                                                        of which you are a member, or in respect of which you are employed, takes part).
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Underage
                                                                        Gambling</p>
                                                                    <p>It is illegal for anyone under the age of 18 to register or gamble on 1xbat. If we identify
                                                                        that you are under 18 or were under 18 when you entered into any transactions on our site:
                                                                        a) We will immediately block you from entering into any betting transactions or withdrawing
                                                                        funds, or using your account;
                                                                        b) We will investigate the claim that you were under age, including whether you have been
                                                                        betting as an agent for or on behalf of another person;
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Cancellation
                                                                        of an Offer for a Bet</p>
                                                                    <p>Your cancellation of an unmatched bet becomes effective once we send confirmation of the
                                                                        cancellation. In the event that your cancellation request is not received and processed in
                                                                        good time, you acknowledge that your offer may remain outstanding and available for
                                                                        acceptance.
                                                                        You may not be able to cancel your offer if your bet has been partially or wholly accepted
                                                                        during the interval between your submitting your cancellation request and the confirmation
                                                                        of said request. In this case, the original offer, or part thereof, will be valid and the
                                                                        bet will be concluded.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Minimum and
                                                                        Maximum Bet Stakes</p>
                                                                    <p>The minimum and maximum bet depends on the product and/or type of market you are betting on.
                                                                        The amounts are subject to change and may differ depending on the platforms used. You are
                                                                        not allowed to bet below the minimum bet size threshold and doing so may result to account
                                                                        closure
                                                                        Your betting limit is represented by your “Available to Bet” balance and “Exposure Limit” as
                                                                        shown in your account, whichever is lower. In the event that we process an offer for a bet
                                                                        or the acceptance of a bet in an amount outside the applicable thresholds, such bet will
                                                                        nonetheless stand.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Bet
                                                                        Settlement</p>
                                                                    <p>Subject to these General Conditions and any applicable Specific Conditions, we will settle
                                                                        markets according to our 1xbat Rules and Regulations, Sportsbook Rules and Regulations. We
                                                                        reserve the right to reverse or amend settlement in the event of a market being settled
                                                                        incorrectly. This may lead to amendments being made to your account to reflect changes in
                                                                        market settlement and if there are insufficient funds in your account we may demand that you
                                                                        put funds into your account to address the outstanding balance. We will notify you if your
                                                                        account goes into a negative balance and, if following the notification, you either:
                                                                        a) do not put funds in your account within the reasonable time specified in our notice to
                                                                        you, or
                                                                        b) you fail to make the relevant payments into your account in accordance with any repayment
                                                                        plan as agreed between you and 1xbat; or
                                                                        c) you indicate that you have no intention of putting funds in your account to address the
                                                                        outstanding balance, then we reserve the right to suspend your account and/or take
                                                                        appropriate legal action to seek to recover the debt, in full, from you.
                                                                        Any amount of winnings or losses relating to your bets on a Market will be rounded to the
                                                                        nearest two decimal places.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Matters
                                                                        beyond our reasonable control</p>
                                                                    <p>Without prejudice to our obligations contained within the various laws and regulations of the
                                                                        jurisdictions where we are licensed, we are not liable for any loss or damage that you may
                                                                        suffer because of any: act of God; power cut; trade or labor dispute; act, failure or
                                                                        omission of any government or authority; obstruction or failure of telecommunication
                                                                        services; or any other delay or failure caused by a third party or otherwise outside of our
                                                                        control. In such an event, we reserve the right to cancel or suspend our services without
                                                                        incurring any liability.

                                                                        We are not liable for the failure of any equipment or software howsoever caused, wherever
                                                                        located or administered, or whether under our direct control or not, that may prevent the
                                                                        operation of the Services, impede the placing of offers for bets or the matching of bets, or
                                                                        prevent you from being able to contact us.
                                                                        We will not be liable for any failure to perform by a third party to our Agreement.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Indemnity
                                                                    </p>
                                                                    <p>You agree to indemnify and hold us and our associated companies, affiliates, officers,
                                                                        directors, agents and employees harmless from any liabilities, claims, losses or demands
                                                                        made by any third party arising out of your breach of this Agreement (including documents
                                                                        incorporated by reference) or out of your violation of any law or the rights of any third
                                                                        party.
                                                                        You acknowledge that 1xbat and its affiliates will hold information with respect to your
                                                                        identity, including but not limited to your name, address and payment details. You agree
                                                                        that we rely on this information in entering into this Agreement and you agree to hold us
                                                                        harmless against any falsehood or inaccuracy contained in the information you provide us.
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Limitation
                                                                        of Liability</p>
                                                                    <p>We exclude all representations and warranties as to the satisfactory quality and/or fitness
                                                                        for its intended purpose and/or accuracy and completeness of the Services.
                                                                        We will not be liable to you for any loss that you may incur as a result of misuse of your
                                                                        password, or in the case of the Telephone Service, of your telephone access number, and we
                                                                        accept no liability resulting from its unauthorized use, whether fraudulent or otherwise.
                                                                        In no event will we, or any of our suppliers, accept any liability however arising for any
                                                                        losses you may incur as a result of using the Services. Under no circumstances will our
                                                                        liability under this Agreement or for breach of contract, tort, equity or otherwise exceed
                                                                        your exposure limit as specified in your account. Under no circumstances will we be liable
                                                                        for any indirect, special or consequential damages, loss of profits (direct or indirect) or
                                                                        the benefit of any bet arising from breach of contract, negligence, equitable duty
                                                                        (including for the avoidance of doubt in relation to any bet(s) or Market(s) voided by us)
                                                                        or other liability even if we had been advised of or known (or should have known) of the
                                                                        possibility of such damages or loss.
                                                                        Nothing in this Agreement excludes our liability with respect to death and personal injury
                                                                        resulting from our negligence, or that of our employees, agents or subcontractors.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Intellectual
                                                                        property</p>
                                                                    <p>1xbat owns all copyright and related rights in and to our site, including, without
                                                                        limitation, rights in databases and all rights in any price data and related content on our
                                                                        site except for certain third-party rights (referred to below);
                                                                        a) all trade mark rights, whether registered or unregistered, in and to 1xbat and the
                                                                        1xbat logo; and
                                                                        b) the domain name www.crickex.com which is our uniform resource locator ("URL").

                                                                        The following use of price data or any other data or content from our site or via the API or
                                                                        any other 1xbat data from any other source is strictly prohibited without our prior
                                                                        consent:
                                                                        a) commercial use by any person; and/or
                                                                        b) any use for any purpose by a competitor of the 1xbat group of companies, or an employee,
                                                                        contractor or agent of any such competitor ("Restricted Person"), provided always that
                                                                        Restricted Persons may place bets.

                                                                        Screen scraping, web scraping or any other automated or manual collection of 1xbat Data,
                                                                        for commercial or personal use, by any person is expressly prohibited.
                                                                        Any unauthorized use of any of the above rights may result in prosecution or other action
                                                                        being taken against you.
                                                                        You hereby assign to 1xbat absolutely, any and all copyright and other intellectual
                                                                        property rights throughout the world in all media whether now known or hereafter developed,
                                                                        for the full period of copyright, including by way of present assignment of future copyright
                                                                        and all other rights whatsoever, in any offers for bets or bets placed by you on our site.
                                                                        Any data licensed to 1xbat from third parties is provided for use on our site only and may
                                                                        not be used for any commercial purposes without the consent of such third parties.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Information
                                                                        Services</p>
                                                                    <p>1xbat from time to time may provide you with access to various information and content. This
                                                                        may include form data, results, video streaming, upcoming events, the times and dates of
                                                                        events, current scores, time elapsed, and other information relating to a sporting event.
                                                                        Such information may be supplied:
                                                                        a) via our site (including micro-sites and as part of any runner data and/or results
                                                                        service), emails or any other means of communication; and/or
                                                                        b) via links to third party websites.

                                                                        Such information is described in these Terms and Conditions collectively as the
                                                                        "Information".
                                                                        The Information is provided by 1xbat or is sourced from a third party. Although some
                                                                        Information may be described as "live", or it may be implied that such Information is
                                                                        "live", you should be aware that this data may be subject to a time delay. The Information
                                                                        may also be inaccurate. You should also be aware that other 1xbat users may have access to
                                                                        pictures and/or data that is faster and/or more accurate than the Information. The
                                                                        Information is supplied "as is" and is provided for guidance only. To the fullest extent
                                                                        permitted by law, 1xbat makes no representations or warranties of any kind, including but
                                                                        not limited to, the accuracy, quality, completeness or timeliness of the Information.
                                                                        If you rely on the Information to place bets, you do so entirely at your own risk. 1xbat
                                                                        shall not be liable for any action taken or not taken by you as a result of your reliance on
                                                                        the Information or for any loss or damage (direct or indirect) suffered by you as a result
                                                                        of your use of the Information.
                                                                        Any links to third party websites does not constitute an endorsement by 1xbat of any
                                                                        products or services available on such websites. You use such websites at your own risk and
                                                                        1xbat takes no responsibility for the content on, or use of, such websites.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Assignment
                                                                    </p>
                                                                    <p>You may not assign any part of this Agreement without 1xbat' written consent but 1xbat may
                                                                        assign any or all of this Agreement to any third party at any time. In the event of an
                                                                        assignment by 1xbat, we will notify of this change and, if you do not agree to this, then
                                                                        you will not be able to continue to use our services, but you shall always be able to
                                                                        withdraw your funds subject to these Terms and Conditions.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Severability
                                                                    </p>
                                                                    <p>In the event that any provision of this Agreement is deemed by any competent authority to be
                                                                        unenforceable or invalid, the relevant provision shall be modified to allow it to be
                                                                        enforced in line with the intention of the original text to the fullest extent permitted by
                                                                        applicable law. The validity and enforceability of the remaining provisions of this
                                                                        Agreement shall not be affected.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Dispute
                                                                        resolution</p>
                                                                    <p>In the event of a dispute arising between us and you, we each agree to follow the procedure
                                                                        set out in our Dispute Resolution policy as amended from time to time.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Amendments
                                                                    </p>
                                                                    <p>We reserve the right to make immaterial changes to our site and all guides and policies
                                                                        included on our site, including this Agreement at any time.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">No waiver
                                                                    </p>
                                                                    <p>No failure or delay by a party to exercise any of its rights under this Agreement shall
                                                                        operate as a waiver thereof and no single or partial exercise of any such right shall
                                                                        prevent any other or further exercise of that or any other right.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             : page == "privacy-policy" ?
                                             <div className="info-page-card v-card v-sheet theme--light">
                                                 <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                                     <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                                         <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                                             <div className="v-expansion-panel-content__wrap">
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Terms &amp;
                                                                         Conditions</p>
                                                                     <p>Welcome to 1xbat, Bangladesh #1 Cricket Exchange &amp; Betting Platform!
                                                                         Register with us and start betting.
                                                                         By opening an account with us, you agree to the following Terms and Conditions.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Account
                                                                         Terms and Conditions</p>
                                                                     <p>To use our services, simply register an account and deposit funds.
                                                                         Please read our Privacy Policy, Rules and Regulations, Responsible Gambling, and FAQs
                                                                         including the provisions with BAU88 Charges that are incorporated into the Terms and
                                                                         Conditions.
                                                                         Should there be any inconsistencies between existing provisions, the Terms and Conditions
                                                                         will prevail.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Your Account
                                                                     </p>
                                                                     <p>By opening an account with us, the following representations are required:
                                                                         a) You are at least 18 years of age. Gambling for minors is strictly prohibited and is
                                                                         punishable by law;
                                                                         b) You are capable of taking responsibility of your actions, and can legally bind an
                                                                         agreement with us;
                                                                         c) You agree to provide accurate information, including but not limited to date of birth and
                                                                         country of residence. Furthermore, you agree to inform us of any changes in the foregoing
                                                                         information;
                                                                         d) You are the person whose details are provided in the registration process;
                                                                         e) You are acting as a principal and not as an agent on behalf of a third party;
                                                                         f) You are not an undischarged bankrupt or in a voluntary arrangement with your creditors;
                                                                         g) You are fully responsible in complying with your local, national, federal, state, or
                                                                         other laws regarding betting and gambling in connection with account registration, placing
                                                                         bets, and using any of our services;
                                                                         h) You will provide proper documentation and information upon request to identify your
                                                                         status, age, address, country of birth, and other such details deemed necessary in
                                                                         connection with opening and maintenance of your account. Furthermore, you agree that we
                                                                         verify the credibility of such information.
 
                                                                         It is your responsibility to update the foregoing information of any changes.
                                                                         You are responsible for the security and confidentiality of your account. This includes your
                                                                         username and password that is used in logging into your account.
                                                                         You should frequently change your password and never disclose the same to any third party.
                                                                         You undertake to protect your username and password and take full responsibility for any
                                                                         risks related to it. If another person manages to access your account, you are solely
                                                                         responsible for all their actions whether or not their access was authorized by you.
                                                                         Furthermore, you hereby indemnify us and hold us harmless against all costs, claims,
                                                                         expenses, and damages arising in connection with the use of your account by any third party.
                                                                         You will not sell, attempt to sell, or transfer the benefit of your account to any third
                                                                         party nor will you acquire or attempt to acquire an account opened and registered in the
                                                                         name of a third party.
 
                                                                         Manage your gambling activities fairly, securely, and responsibly.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit and
                                                                         Withdrawal of Funds</p>
                                                                     <p>To start betting, you need to deposit funds.
                                                                         You undertake that:
                                                                         a) All money deposited in your account is untainted with any illegality and does not
                                                                         originated from any illegal activity or source;
                                                                         b) All payments made into your account are authorized and you will not attempt to reverse
                                                                         any payment made into your account or take any action to such extent made by as third party
                                                                         in order to avoid any liability.
                                                                         c) You accept that all transactions may be checked to prevent money laundering and any
                                                                         suspicious transactions will be reported to appropriate authorities.
                                                                         d) You agree that you are not entitled to any interest in your deposits and acknowledge that
                                                                         you shall not treat 1xbat as a financial institution.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                     <p>All information sent to and received by us are processed under our Privacy Policy.
                                                                         You authorize us, at any time, to use any means necessary to verify your identity and
                                                                         credentials with any third-party providers of information.
                                                                         You agree that, from time to time, we may contact you to inquire about any promotional
                                                                         activity should you win a large sum of money, or place an unusually successful bet. Should
                                                                         you wish to accept, we may use your name and/or image as necessary.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit and
                                                                         Withdrawal of Funds</p>
                                                                     <p>To start betting, you need to deposit funds.
                                                                         You undertake that:
                                                                         a) All money deposited in your account is untainted with any illegality and does not
                                                                         originated from any illegal activity or source;
                                                                         b) All payments made into your account are authorized and you will not attempt to reverse
                                                                         any payment made into your account or take any action to such extent made by as third party
                                                                         in order to avoid any liability.
                                                                         c) You accept that all transactions may be checked to prevent money laundering and any
                                                                         suspicious transactions will be reported to appropriate authorities.
                                                                         d) You agree that you are not entitled to any interest in your deposits and acknowledge that
                                                                         you shall not treat 1xbat as a financial institution.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                     <p>All information sent to and received by us are processed under our Privacy Policy.
                                                                         You authorize us, at any time, to use any means necessary to verify your identity and
                                                                         credentials with any third-party providers of information.
                                                                         You agree that, from time to time, we may contact you to inquire about any promotional
                                                                         activity should you win a large sum of money, or place an unusually successful bet. Should
                                                                         you wish to accept, we may use your name and/or image as necessary.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Indemnity
                                                                     </p>
                                                                     <p>You acknowledge that 1xbat and its affiliates shall hold information with respect to your
                                                                         identity, including your name, address, and payment details. You agree that we rely on this
                                                                         information in entering into our agreement with you. You further agree to hold us free from
                                                                         any falsehood or inaccuracy in the information given to us.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Errors and
                                                                         Omissions</p>
                                                                     <p>You will inform us as soon as you discover any error in your account and you will forfeit any
                                                                         amounts shown in your account resulting from human or technical error.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Suspension
                                                                         and Termination</p>
                                                                     <p>We may, at our sole discretion, suspend or terminate your account at any time, with or
                                                                         without cause, upon notice. You may terminate your account at any given time. Just contact
                                                                         our Customer Service should you wish to do so.
                                                                         We may block you from betting or using our services if you are found to be operating in
                                                                         areas outside our legal jurisdiction.
                                                                         We reserve the right to conduct an investigation on any suspicious activities with regards
                                                                         to your account. Upon the determination of any investigation, we reserve the right to seize
                                                                         some or all of the funds in your account if you are found to have violated any of the
                                                                         provisions of the Terms and Conditions. Should it be required, we may suspend your account
                                                                         immediately.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Severability
                                                                     </p>
                                                                     <p>In the event that any provision of the Terms and Conditions is deemed by any competent
                                                                         authority to be unenforceable or invalid, the relevant provision shall be modified to be
                                                                         allow its enforceability with respect to the intent of the original text. The validity of
                                                                         the remaining provisions shall not be affected by such change.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Amendments
                                                                     </p>
                                                                     <p>We reserve the right to make any changes to these Terms and Conditions at any time. If you do
                                                                         not agree to such changes, you will not be able to continue to use our services. </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Betting
                                                                         General Conditions
                                                                     </p>
                                                                     <p>1xbat offers a platform where players may enter into various betting transactions in
                                                                         relation to the markets available on our sites.
                                                                         Where players bet using our services, 1xbat acts as a facilitator and does not act as a
                                                                         counterparty.
                                                                         You may enter into betting transactions through our website or through our mobile
                                                                         application;
 
                                                                         In these General Conditions when we refer to "the Services" we mean all the services listed
                                                                         above (where applicable).
                                                                         In these General Conditions references to "1xbat", "we", "us" and/or "our" are to whichever
                                                                         1xbat is providing the Services to you as set out in the Specific Conditions applicable to
                                                                         your betting transaction.
                                                                         Please read these General Conditions, and the Specific Conditions applicable to you and your
                                                                         betting transaction carefully before entering into any betting transactions.
                                                                         Please note that there are separate Rules and Regulations for each of our exchange and
                                                                         products. The rules governing how markets are offered, managed and/or settled are not the
                                                                         same for every market on each product. In certain circumstances, a bet that is settled as a
                                                                         winner on one product may be settled as a loser on the other product (and vice versa).
                                                                         Additionally, different settlement rules may apply so that, for example, bets that are a
                                                                         winner on one product may be settled as a dead heat or be voided on the other product. Users
                                                                         must ensure that they familiarize themselves with the relevant rules that apply to the bets
                                                                         that they place on 1xbat.
                                                                         Should there be any inconsistencies between the documents mentioned, this document, and the
                                                                         relevant Specific Conditions, the Specific Conditions will prevail over this document, and
                                                                         this document will prevail over the documents listed above.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Conditions
                                                                         on Using 1xbat</p>
                                                                     <p>info.info_tnc_lc_desc</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Use of Our
                                                                         Services</p>
                                                                     <p>You are responsible for any money lost resulting from losing bets. You accept that under no
                                                                         circumstances will any amounts lost by you under any bet be recoverable from us.
                                                                         It is your responsibility to verify that you are permitted to bet with us according to the
                                                                         jurisdiction of your domicile or habitual residence and/or in which you are located and you
                                                                         agree that you will not bet with us while you are located in a jurisdiction which prohibits
                                                                         you from betting with us.
                                                                         You undertake to use our services for legitimate betting purposes only. In particular you
                                                                         undertake not to
                                                                         a) engage in any activity which has the purpose or effect of causing damage to or in any way
                                                                         hindering our business operations or generating or increasing a liability on us, including
                                                                         without limitation any tax, levy or duty collecting authority;
                                                                         b) bet on, or manipulate any individual Market in a manner which we believe has the purpose
                                                                         or effect of adversely affecting the integrity of the Exchange or any Market;
                                                                         c) transmit information that assists in the placing of bets or wagers on any sporting event
                                                                         or contest from any territory where it is illegal to do so; and/ or
                                                                         d) procure or otherwise arrange, whether directly or indirectly, for a third party to
                                                                         transmit information that assists in the placing of bets or wagers on any sporting event or
                                                                         contest from any territory where it is illegal to do so.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">General
                                                                         Conduct</p>
                                                                     <p>You will not attempt to use our services to deliberately transfer money from one 1xbat
                                                                         account to another by attempting to match bets on a market with another account through
                                                                         collusion.
                                                                         You will inform us as soon as you become aware of any errors betting calculations. We
                                                                         reserve the right to declare null and void any erroneous bets.
                                                                         You will not or interfere in any way with the IP address of the computer you are using to
                                                                         access the site or otherwise take steps to prevent us from correctly identifying the actual
                                                                         IP address of the computer you are using whilst accessing the site.
                                                                         You agree it is your responsibility to account for any tax or duty imposed on you as a
                                                                         result of any of your betting transactions.
                                                                         You will not make offensive comments, use offensive or pornographic material or make
                                                                         potentially defamatory or inflammatory remarks in connection with the forums or any other
                                                                         part of our site including within your own username or other information contributed to our
                                                                         site.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                     <p>Any information you send to us will be processed in accordance with our Privacy Policy.
                                                                         You authorize us, at any time, to use any means necessary to verify your identity and
                                                                         credentials with any third-party providers of information.
                                                                         We reserve the right to record all communications made to and from 1xbat and to monitor all
                                                                         information relating to betting activities
                                                                         You agree to indemnify us and hold us harmless in respect of the release of any personal (or
                                                                         other) data pursuant to this Agreement.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Conditions
                                                                         Relating to Betting</p>
                                                                     <p>You are responsible for understanding the contents of our site and the operation of the
                                                                         Services; we reserve the right to change the format of the Services in order to enhance such
                                                                         We may determine when the Markets are open for betting and may close the Markets at any time
                                                                         in our absolute discretion. We will at all times use reasonable endeavors to settle Markets
                                                                         as quickly as possible, we give no assurances however, as to the timeframe in which Markets
                                                                         will be settled.
                                                                         You are entirely responsible for the information you give us concerning the offer that you
                                                                         wish to place. We will not be liable for incorrect entries made by you, including data input
                                                                         errors with respect to the odds, price or stake on offer, or incorrect bet requests
                                                                         communicated by you to our operators. If our operators repeat back your bet request
                                                                         incorrectly, it is your responsibility to correct the bet request and inform us of the
                                                                         error. Once you have confirmed the bet request and your bet is matched, you will be liable
                                                                         for that bet if it loses.
                                                                         1xbat may, in its sole and absolute discretion, decide to suspend betting on a market at
                                                                         any time. In the interests of maintaining integrity and fairness in the Markets, 1xbat may
                                                                         also void certain bets in a market or void a whole Market in its entirety.
 
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                                         Cancellation, Termination, Suspension, and Breach</p>
                                                                     <p>We may restrict your access to 1xbat, suspend or terminate your account, withdraw your
                                                                         offers for bets, void any bets outstanding to your account, cancel any unmatched bets or
                                                                         cancel and void any outstanding or matched bets in our absolute discretion without cause at
                                                                         any time if:
                                                                         there is a technological failure;
                                                                         a) we suspect that you are engaging in illegal or fraudulent activity;
                                                                         b) we suspect that you have (or may have) breached any part of this Agreement;
                                                                         c) we suspect that you have breached the terms of a 1xbat promotion;
                                                                         d) we suspect that you are acting in collusion with other players to take unfair advantage
                                                                         of a bet/promotion and/or in the course of participating in a bet/promotion, you have become
                                                                         able to guarantee wins and/or profits with no or only minimal risk;
                                                                         e) we suspect that your account's "public market data" usage ('public market data' includes
                                                                         market prices, traded volumes and market depth) could represent business usage (˜'business
                                                                         usage' includes any use by a betting operator or any use by an individual or organization
                                                                         supplying data or services to a betting operator)
                                                                         f) you are prohibited from entering into a bet by any term of your contract of employment or
                                                                         any rule of a sport governing body or other professional body which applies to you; or
                                                                         g) you place a bet on any sporting match or event in which you take part (or in which a team
                                                                         of which you are a member, or in respect of which you are employed, takes part).
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Underage
                                                                         Gambling</p>
                                                                     <p>It is illegal for anyone under the age of 18 to register or gamble on 1xbat. If we identify
                                                                         that you are under 18 or were under 18 when you entered into any transactions on our site:
                                                                         a) We will immediately block you from entering into any betting transactions or withdrawing
                                                                         funds, or using your account;
                                                                         b) We will investigate the claim that you were under age, including whether you have been
                                                                         betting as an agent for or on behalf of another person;
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Cancellation
                                                                         of an Offer for a Bet</p>
                                                                     <p>Your cancellation of an unmatched bet becomes effective once we send confirmation of the
                                                                         cancellation. In the event that your cancellation request is not received and processed in
                                                                         good time, you acknowledge that your offer may remain outstanding and available for
                                                                         acceptance.
                                                                         You may not be able to cancel your offer if your bet has been partially or wholly accepted
                                                                         during the interval between your submitting your cancellation request and the confirmation
                                                                         of said request. In this case, the original offer, or part thereof, will be valid and the
                                                                         bet will be concluded.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Minimum and
                                                                         Maximum Bet Stakes</p>
                                                                     <p>The minimum and maximum bet depends on the product and/or type of market you are betting on.
                                                                         The amounts are subject to change and may differ depending on the platforms used. You are
                                                                         not allowed to bet below the minimum bet size threshold and doing so may result to account
                                                                         closure
                                                                         Your betting limit is represented by your “Available to Bet” balance and “Exposure Limit” as
                                                                         shown in your account, whichever is lower. In the event that we process an offer for a bet
                                                                         or the acceptance of a bet in an amount outside the applicable thresholds, such bet will
                                                                         nonetheless stand.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Bet
                                                                         Settlement</p>
                                                                     <p>Subject to these General Conditions and any applicable Specific Conditions, we will settle
                                                                         markets according to our 1xbat Rules and Regulations, Sportsbook Rules and Regulations. We
                                                                         reserve the right to reverse or amend settlement in the event of a market being settled
                                                                         incorrectly. This may lead to amendments being made to your account to reflect changes in
                                                                         market settlement and if there are insufficient funds in your account we may demand that you
                                                                         put funds into your account to address the outstanding balance. We will notify you if your
                                                                         account goes into a negative balance and, if following the notification, you either:
                                                                         a) do not put funds in your account within the reasonable time specified in our notice to
                                                                         you, or
                                                                         b) you fail to make the relevant payments into your account in accordance with any repayment
                                                                         plan as agreed between you and 1xbat; or
                                                                         c) you indicate that you have no intention of putting funds in your account to address the
                                                                         outstanding balance, then we reserve the right to suspend your account and/or take
                                                                         appropriate legal action to seek to recover the debt, in full, from you.
                                                                         Any amount of winnings or losses relating to your bets on a Market will be rounded to the
                                                                         nearest two decimal places.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Matters
                                                                         beyond our reasonable control</p>
                                                                     <p>Without prejudice to our obligations contained within the various laws and regulations of the
                                                                         jurisdictions where we are licensed, we are not liable for any loss or damage that you may
                                                                         suffer because of any: act of God; power cut; trade or labor dispute; act, failure or
                                                                         omission of any government or authority; obstruction or failure of telecommunication
                                                                         services; or any other delay or failure caused by a third party or otherwise outside of our
                                                                         control. In such an event, we reserve the right to cancel or suspend our services without
                                                                         incurring any liability.
 
                                                                         We are not liable for the failure of any equipment or software howsoever caused, wherever
                                                                         located or administered, or whether under our direct control or not, that may prevent the
                                                                         operation of the Services, impede the placing of offers for bets or the matching of bets, or
                                                                         prevent you from being able to contact us.
                                                                         We will not be liable for any failure to perform by a third party to our Agreement.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Indemnity
                                                                     </p>
                                                                     <p>You agree to indemnify and hold us and our associated companies, affiliates, officers,
                                                                         directors, agents and employees harmless from any liabilities, claims, losses or demands
                                                                         made by any third party arising out of your breach of this Agreement (including documents
                                                                         incorporated by reference) or out of your violation of any law or the rights of any third
                                                                         party.
                                                                         You acknowledge that 1xbat and its affiliates will hold information with respect to your
                                                                         identity, including but not limited to your name, address and payment details. You agree
                                                                         that we rely on this information in entering into this Agreement and you agree to hold us
                                                                         harmless against any falsehood or inaccuracy contained in the information you provide us.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Limitation
                                                                         of Liability</p>
                                                                     <p>We exclude all representations and warranties as to the satisfactory quality and/or fitness
                                                                         for its intended purpose and/or accuracy and completeness of the Services.
                                                                         We will not be liable to you for any loss that you may incur as a result of misuse of your
                                                                         password, or in the case of the Telephone Service, of your telephone access number, and we
                                                                         accept no liability resulting from its unauthorized use, whether fraudulent or otherwise.
                                                                         In no event will we, or any of our suppliers, accept any liability however arising for any
                                                                         losses you may incur as a result of using the Services. Under no circumstances will our
                                                                         liability under this Agreement or for breach of contract, tort, equity or otherwise exceed
                                                                         your exposure limit as specified in your account. Under no circumstances will we be liable
                                                                         for any indirect, special or consequential damages, loss of profits (direct or indirect) or
                                                                         the benefit of any bet arising from breach of contract, negligence, equitable duty
                                                                         (including for the avoidance of doubt in relation to any bet(s) or Market(s) voided by us)
                                                                         or other liability even if we had been advised of or known (or should have known) of the
                                                                         possibility of such damages or loss.
                                                                         Nothing in this Agreement excludes our liability with respect to death and personal injury
                                                                         resulting from our negligence, or that of our employees, agents or subcontractors.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Intellectual
                                                                         property</p>
                                                                     <p>1xbat owns all copyright and related rights in and to our site, including, without
                                                                         limitation, rights in databases and all rights in any price data and related content on our
                                                                         site except for certain third-party rights (referred to below);
                                                                         a) all trade mark rights, whether registered or unregistered, in and to 1xbat and the
                                                                         1xbat logo; and
                                                                         b) the domain name www.crickex.com which is our uniform resource locator ("URL").
 
                                                                         The following use of price data or any other data or content from our site or via the API or
                                                                         any other 1xbat data from any other source is strictly prohibited without our prior
                                                                         consent:
                                                                         a) commercial use by any person; and/or
                                                                         b) any use for any purpose by a competitor of the 1xbat group of companies, or an employee,
                                                                         contractor or agent of any such competitor ("Restricted Person"), provided always that
                                                                         Restricted Persons may place bets.
 
                                                                         Screen scraping, web scraping or any other automated or manual collection of 1xbat Data,
                                                                         for commercial or personal use, by any person is expressly prohibited.
                                                                         Any unauthorized use of any of the above rights may result in prosecution or other action
                                                                         being taken against you.
                                                                         You hereby assign to 1xbat absolutely, any and all copyright and other intellectual
                                                                         property rights throughout the world in all media whether now known or hereafter developed,
                                                                         for the full period of copyright, including by way of present assignment of future copyright
                                                                         and all other rights whatsoever, in any offers for bets or bets placed by you on our site.
                                                                         Any data licensed to 1xbat from third parties is provided for use on our site only and may
                                                                         not be used for any commercial purposes without the consent of such third parties.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Information
                                                                         Services</p>
                                                                     <p>1xbat from time to time may provide you with access to various information and content. This
                                                                         may include form data, results, video streaming, upcoming events, the times and dates of
                                                                         events, current scores, time elapsed, and other information relating to a sporting event.
                                                                         Such information may be supplied:
                                                                         a) via our site (including micro-sites and as part of any runner data and/or results
                                                                         service), emails or any other means of communication; and/or
                                                                         b) via links to third party websites.
 
                                                                         Such information is described in these Terms and Conditions collectively as the
                                                                         "Information".
                                                                         The Information is provided by 1xbat or is sourced from a third party. Although some
                                                                         Information may be described as "live", or it may be implied that such Information is
                                                                         "live", you should be aware that this data may be subject to a time delay. The Information
                                                                         may also be inaccurate. You should also be aware that other 1xbat users may have access to
                                                                         pictures and/or data that is faster and/or more accurate than the Information. The
                                                                         Information is supplied "as is" and is provided for guidance only. To the fullest extent
                                                                         permitted by law, 1xbat makes no representations or warranties of any kind, including but
                                                                         not limited to, the accuracy, quality, completeness or timeliness of the Information.
                                                                         If you rely on the Information to place bets, you do so entirely at your own risk. 1xbat
                                                                         shall not be liable for any action taken or not taken by you as a result of your reliance on
                                                                         the Information or for any loss or damage (direct or indirect) suffered by you as a result
                                                                         of your use of the Information.
                                                                         Any links to third party websites does not constitute an endorsement by 1xbat of any
                                                                         products or services available on such websites. You use such websites at your own risk and
                                                                         1xbat takes no responsibility for the content on, or use of, such websites.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Assignment
                                                                     </p>
                                                                     <p>You may not assign any part of this Agreement without 1xbat' written consent but 1xbat may
                                                                         assign any or all of this Agreement to any third party at any time. In the event of an
                                                                         assignment by 1xbat, we will notify of this change and, if you do not agree to this, then
                                                                         you will not be able to continue to use our services, but you shall always be able to
                                                                         withdraw your funds subject to these Terms and Conditions.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Severability
                                                                     </p>
                                                                     <p>In the event that any provision of this Agreement is deemed by any competent authority to be
                                                                         unenforceable or invalid, the relevant provision shall be modified to allow it to be
                                                                         enforced in line with the intention of the original text to the fullest extent permitted by
                                                                         applicable law. The validity and enforceability of the remaining provisions of this
                                                                         Agreement shall not be affected.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Dispute
                                                                         resolution</p>
                                                                     <p>In the event of a dispute arising between us and you, we each agree to follow the procedure
                                                                         set out in our Dispute Resolution policy as amended from time to time.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Amendments
                                                                     </p>
                                                                     <p>We reserve the right to make immaterial changes to our site and all guides and policies
                                                                         included on our site, including this Agreement at any time.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">No waiver
                                                                     </p>
                                                                     <p>No failure or delay by a party to exercise any of its rights under this Agreement shall
                                                                         operate as a waiver thereof and no single or partial exercise of any such right shall
                                                                         prevent any other or further exercise of that or any other right.</p>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                             : page == "rule-regulation" ?
                                             <div className="info-page-card v-card v-sheet theme--light">
                                                 <div className="v-item-group theme--light v-expansion-panels v-expansion-panels--accordion">
                                                     <div aria-expanded="true" className="v-expansion-panel v-expansion-panel--active v-item--active">
                                                         <div className="v-expansion-panel-content py-4 info-expension-panel-content">
                                                             <div className="v-expansion-panel-content__wrap">
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Terms &amp;
                                                                         Conditions</p>
                                                                     <p>Welcome to 1xbat, Bangladesh #1 Cricket Exchange &amp; Betting Platform!
                                                                         Register with us and start betting.
                                                                         By opening an account with us, you agree to the following Terms and Conditions.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Account
                                                                         Terms and Conditions</p>
                                                                     <p>To use our services, simply register an account and deposit funds.
                                                                         Please read our Privacy Policy, Rules and Regulations, Responsible Gambling, and FAQs
                                                                         including the provisions with BAU88 Charges that are incorporated into the Terms and
                                                                         Conditions.
                                                                         Should there be any inconsistencies between existing provisions, the Terms and Conditions
                                                                         will prevail.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Your Account
                                                                     </p>
                                                                     <p>By opening an account with us, the following representations are required:
                                                                         a) You are at least 18 years of age. Gambling for minors is strictly prohibited and is
                                                                         punishable by law;
                                                                         b) You are capable of taking responsibility of your actions, and can legally bind an
                                                                         agreement with us;
                                                                         c) You agree to provide accurate information, including but not limited to date of birth and
                                                                         country of residence. Furthermore, you agree to inform us of any changes in the foregoing
                                                                         information;
                                                                         d) You are the person whose details are provided in the registration process;
                                                                         e) You are acting as a principal and not as an agent on behalf of a third party;
                                                                         f) You are not an undischarged bankrupt or in a voluntary arrangement with your creditors;
                                                                         g) You are fully responsible in complying with your local, national, federal, state, or
                                                                         other laws regarding betting and gambling in connection with account registration, placing
                                                                         bets, and using any of our services;
                                                                         h) You will provide proper documentation and information upon request to identify your
                                                                         status, age, address, country of birth, and other such details deemed necessary in
                                                                         connection with opening and maintenance of your account. Furthermore, you agree that we
                                                                         verify the credibility of such information.
 
                                                                         It is your responsibility to update the foregoing information of any changes.
                                                                         You are responsible for the security and confidentiality of your account. This includes your
                                                                         username and password that is used in logging into your account.
                                                                         You should frequently change your password and never disclose the same to any third party.
                                                                         You undertake to protect your username and password and take full responsibility for any
                                                                         risks related to it. If another person manages to access your account, you are solely
                                                                         responsible for all their actions whether or not their access was authorized by you.
                                                                         Furthermore, you hereby indemnify us and hold us harmless against all costs, claims,
                                                                         expenses, and damages arising in connection with the use of your account by any third party.
                                                                         You will not sell, attempt to sell, or transfer the benefit of your account to any third
                                                                         party nor will you acquire or attempt to acquire an account opened and registered in the
                                                                         name of a third party.
 
                                                                         Manage your gambling activities fairly, securely, and responsibly.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit and
                                                                         Withdrawal of Funds</p>
                                                                     <p>To start betting, you need to deposit funds.
                                                                         You undertake that:
                                                                         a) All money deposited in your account is untainted with any illegality and does not
                                                                         originated from any illegal activity or source;
                                                                         b) All payments made into your account are authorized and you will not attempt to reverse
                                                                         any payment made into your account or take any action to such extent made by as third party
                                                                         in order to avoid any liability.
                                                                         c) You accept that all transactions may be checked to prevent money laundering and any
                                                                         suspicious transactions will be reported to appropriate authorities.
                                                                         d) You agree that you are not entitled to any interest in your deposits and acknowledge that
                                                                         you shall not treat 1xbat as a financial institution.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                     <p>All information sent to and received by us are processed under our Privacy Policy.
                                                                         You authorize us, at any time, to use any means necessary to verify your identity and
                                                                         credentials with any third-party providers of information.
                                                                         You agree that, from time to time, we may contact you to inquire about any promotional
                                                                         activity should you win a large sum of money, or place an unusually successful bet. Should
                                                                         you wish to accept, we may use your name and/or image as necessary.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Deposit and
                                                                         Withdrawal of Funds</p>
                                                                     <p>To start betting, you need to deposit funds.
                                                                         You undertake that:
                                                                         a) All money deposited in your account is untainted with any illegality and does not
                                                                         originated from any illegal activity or source;
                                                                         b) All payments made into your account are authorized and you will not attempt to reverse
                                                                         any payment made into your account or take any action to such extent made by as third party
                                                                         in order to avoid any liability.
                                                                         c) You accept that all transactions may be checked to prevent money laundering and any
                                                                         suspicious transactions will be reported to appropriate authorities.
                                                                         d) You agree that you are not entitled to any interest in your deposits and acknowledge that
                                                                         you shall not treat 1xbat as a financial institution.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                     <p>All information sent to and received by us are processed under our Privacy Policy.
                                                                         You authorize us, at any time, to use any means necessary to verify your identity and
                                                                         credentials with any third-party providers of information.
                                                                         You agree that, from time to time, we may contact you to inquire about any promotional
                                                                         activity should you win a large sum of money, or place an unusually successful bet. Should
                                                                         you wish to accept, we may use your name and/or image as necessary.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Indemnity
                                                                     </p>
                                                                     <p>You acknowledge that 1xbat and its affiliates shall hold information with respect to your
                                                                         identity, including your name, address, and payment details. You agree that we rely on this
                                                                         information in entering into our agreement with you. You further agree to hold us free from
                                                                         any falsehood or inaccuracy in the information given to us.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Errors and
                                                                         Omissions</p>
                                                                     <p>You will inform us as soon as you discover any error in your account and you will forfeit any
                                                                         amounts shown in your account resulting from human or technical error.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Suspension
                                                                         and Termination</p>
                                                                     <p>We may, at our sole discretion, suspend or terminate your account at any time, with or
                                                                         without cause, upon notice. You may terminate your account at any given time. Just contact
                                                                         our Customer Service should you wish to do so.
                                                                         We may block you from betting or using our services if you are found to be operating in
                                                                         areas outside our legal jurisdiction.
                                                                         We reserve the right to conduct an investigation on any suspicious activities with regards
                                                                         to your account. Upon the determination of any investigation, we reserve the right to seize
                                                                         some or all of the funds in your account if you are found to have violated any of the
                                                                         provisions of the Terms and Conditions. Should it be required, we may suspend your account
                                                                         immediately.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Severability
                                                                     </p>
                                                                     <p>In the event that any provision of the Terms and Conditions is deemed by any competent
                                                                         authority to be unenforceable or invalid, the relevant provision shall be modified to be
                                                                         allow its enforceability with respect to the intent of the original text. The validity of
                                                                         the remaining provisions shall not be affected by such change.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Amendments
                                                                     </p>
                                                                     <p>We reserve the right to make any changes to these Terms and Conditions at any time. If you do
                                                                         not agree to such changes, you will not be able to continue to use our services. </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Betting
                                                                         General Conditions
                                                                     </p>
                                                                     <p>1xbat offers a platform where players may enter into various betting transactions in
                                                                         relation to the markets available on our sites.
                                                                         Where players bet using our services, 1xbat acts as a facilitator and does not act as a
                                                                         counterparty.
                                                                         You may enter into betting transactions through our website or through our mobile
                                                                         application;
 
                                                                         In these General Conditions when we refer to "the Services" we mean all the services listed
                                                                         above (where applicable).
                                                                         In these General Conditions references to "1xbat", "we", "us" and/or "our" are to whichever
                                                                         1xbat is providing the Services to you as set out in the Specific Conditions applicable to
                                                                         your betting transaction.
                                                                         Please read these General Conditions, and the Specific Conditions applicable to you and your
                                                                         betting transaction carefully before entering into any betting transactions.
                                                                         Please note that there are separate Rules and Regulations for each of our exchange and
                                                                         products. The rules governing how markets are offered, managed and/or settled are not the
                                                                         same for every market on each product. In certain circumstances, a bet that is settled as a
                                                                         winner on one product may be settled as a loser on the other product (and vice versa).
                                                                         Additionally, different settlement rules may apply so that, for example, bets that are a
                                                                         winner on one product may be settled as a dead heat or be voided on the other product. Users
                                                                         must ensure that they familiarize themselves with the relevant rules that apply to the bets
                                                                         that they place on 1xbat.
                                                                         Should there be any inconsistencies between the documents mentioned, this document, and the
                                                                         relevant Specific Conditions, the Specific Conditions will prevail over this document, and
                                                                         this document will prevail over the documents listed above.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Conditions
                                                                         on Using 1xbat</p>
                                                                     <p>info.info_tnc_lc_desc</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Use of Our
                                                                         Services</p>
                                                                     <p>You are responsible for any money lost resulting from losing bets. You accept that under no
                                                                         circumstances will any amounts lost by you under any bet be recoverable from us.
                                                                         It is your responsibility to verify that you are permitted to bet with us according to the
                                                                         jurisdiction of your domicile or habitual residence and/or in which you are located and you
                                                                         agree that you will not bet with us while you are located in a jurisdiction which prohibits
                                                                         you from betting with us.
                                                                         You undertake to use our services for legitimate betting purposes only. In particular you
                                                                         undertake not to
                                                                         a) engage in any activity which has the purpose or effect of causing damage to or in any way
                                                                         hindering our business operations or generating or increasing a liability on us, including
                                                                         without limitation any tax, levy or duty collecting authority;
                                                                         b) bet on, or manipulate any individual Market in a manner which we believe has the purpose
                                                                         or effect of adversely affecting the integrity of the Exchange or any Market;
                                                                         c) transmit information that assists in the placing of bets or wagers on any sporting event
                                                                         or contest from any territory where it is illegal to do so; and/ or
                                                                         d) procure or otherwise arrange, whether directly or indirectly, for a third party to
                                                                         transmit information that assists in the placing of bets or wagers on any sporting event or
                                                                         contest from any territory where it is illegal to do so.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">General
                                                                         Conduct</p>
                                                                     <p>You will not attempt to use our services to deliberately transfer money from one 1xbat
                                                                         account to another by attempting to match bets on a market with another account through
                                                                         collusion.
                                                                         You will inform us as soon as you become aware of any errors betting calculations. We
                                                                         reserve the right to declare null and void any erroneous bets.
                                                                         You will not or interfere in any way with the IP address of the computer you are using to
                                                                         access the site or otherwise take steps to prevent us from correctly identifying the actual
                                                                         IP address of the computer you are using whilst accessing the site.
                                                                         You agree it is your responsibility to account for any tax or duty imposed on you as a
                                                                         result of any of your betting transactions.
                                                                         You will not make offensive comments, use offensive or pornographic material or make
                                                                         potentially defamatory or inflammatory remarks in connection with the forums or any other
                                                                         part of our site including within your own username or other information contributed to our
                                                                         site.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Privacy</p>
                                                                     <p>Any information you send to us will be processed in accordance with our Privacy Policy.
                                                                         You authorize us, at any time, to use any means necessary to verify your identity and
                                                                         credentials with any third-party providers of information.
                                                                         We reserve the right to record all communications made to and from 1xbat and to monitor all
                                                                         information relating to betting activities
                                                                         You agree to indemnify us and hold us harmless in respect of the release of any personal (or
                                                                         other) data pursuant to this Agreement.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Conditions
                                                                         Relating to Betting</p>
                                                                     <p>You are responsible for understanding the contents of our site and the operation of the
                                                                         Services; we reserve the right to change the format of the Services in order to enhance such
                                                                         We may determine when the Markets are open for betting and may close the Markets at any time
                                                                         in our absolute discretion. We will at all times use reasonable endeavors to settle Markets
                                                                         as quickly as possible, we give no assurances however, as to the timeframe in which Markets
                                                                         will be settled.
                                                                         You are entirely responsible for the information you give us concerning the offer that you
                                                                         wish to place. We will not be liable for incorrect entries made by you, including data input
                                                                         errors with respect to the odds, price or stake on offer, or incorrect bet requests
                                                                         communicated by you to our operators. If our operators repeat back your bet request
                                                                         incorrectly, it is your responsibility to correct the bet request and inform us of the
                                                                         error. Once you have confirmed the bet request and your bet is matched, you will be liable
                                                                         for that bet if it loses.
                                                                         1xbat may, in its sole and absolute discretion, decide to suspend betting on a market at
                                                                         any time. In the interests of maintaining integrity and fairness in the Markets, 1xbat may
                                                                         also void certain bets in a market or void a whole Market in its entirety.
 
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">
                                                                         Cancellation, Termination, Suspension, and Breach</p>
                                                                     <p>We may restrict your access to 1xbat, suspend or terminate your account, withdraw your
                                                                         offers for bets, void any bets outstanding to your account, cancel any unmatched bets or
                                                                         cancel and void any outstanding or matched bets in our absolute discretion without cause at
                                                                         any time if:
                                                                         there is a technological failure;
                                                                         a) we suspect that you are engaging in illegal or fraudulent activity;
                                                                         b) we suspect that you have (or may have) breached any part of this Agreement;
                                                                         c) we suspect that you have breached the terms of a 1xbat promotion;
                                                                         d) we suspect that you are acting in collusion with other players to take unfair advantage
                                                                         of a bet/promotion and/or in the course of participating in a bet/promotion, you have become
                                                                         able to guarantee wins and/or profits with no or only minimal risk;
                                                                         e) we suspect that your account's "public market data" usage ('public market data' includes
                                                                         market prices, traded volumes and market depth) could represent business usage (˜'business
                                                                         usage' includes any use by a betting operator or any use by an individual or organization
                                                                         supplying data or services to a betting operator)
                                                                         f) you are prohibited from entering into a bet by any term of your contract of employment or
                                                                         any rule of a sport governing body or other professional body which applies to you; or
                                                                         g) you place a bet on any sporting match or event in which you take part (or in which a team
                                                                         of which you are a member, or in respect of which you are employed, takes part).
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Underage
                                                                         Gambling</p>
                                                                     <p>It is illegal for anyone under the age of 18 to register or gamble on 1xbat. If we identify
                                                                         that you are under 18 or were under 18 when you entered into any transactions on our site:
                                                                         a) We will immediately block you from entering into any betting transactions or withdrawing
                                                                         funds, or using your account;
                                                                         b) We will investigate the claim that you were under age, including whether you have been
                                                                         betting as an agent for or on behalf of another person;
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Cancellation
                                                                         of an Offer for a Bet</p>
                                                                     <p>Your cancellation of an unmatched bet becomes effective once we send confirmation of the
                                                                         cancellation. In the event that your cancellation request is not received and processed in
                                                                         good time, you acknowledge that your offer may remain outstanding and available for
                                                                         acceptance.
                                                                         You may not be able to cancel your offer if your bet has been partially or wholly accepted
                                                                         during the interval between your submitting your cancellation request and the confirmation
                                                                         of said request. In this case, the original offer, or part thereof, will be valid and the
                                                                         bet will be concluded.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Minimum and
                                                                         Maximum Bet Stakes</p>
                                                                     <p>The minimum and maximum bet depends on the product and/or type of market you are betting on.
                                                                         The amounts are subject to change and may differ depending on the platforms used. You are
                                                                         not allowed to bet below the minimum bet size threshold and doing so may result to account
                                                                         closure
                                                                         Your betting limit is represented by your “Available to Bet” balance and “Exposure Limit” as
                                                                         shown in your account, whichever is lower. In the event that we process an offer for a bet
                                                                         or the acceptance of a bet in an amount outside the applicable thresholds, such bet will
                                                                         nonetheless stand.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Bet
                                                                         Settlement</p>
                                                                     <p>Subject to these General Conditions and any applicable Specific Conditions, we will settle
                                                                         markets according to our 1xbat Rules and Regulations, Sportsbook Rules and Regulations. We
                                                                         reserve the right to reverse or amend settlement in the event of a market being settled
                                                                         incorrectly. This may lead to amendments being made to your account to reflect changes in
                                                                         market settlement and if there are insufficient funds in your account we may demand that you
                                                                         put funds into your account to address the outstanding balance. We will notify you if your
                                                                         account goes into a negative balance and, if following the notification, you either:
                                                                         a) do not put funds in your account within the reasonable time specified in our notice to
                                                                         you, or
                                                                         b) you fail to make the relevant payments into your account in accordance with any repayment
                                                                         plan as agreed between you and 1xbat; or
                                                                         c) you indicate that you have no intention of putting funds in your account to address the
                                                                         outstanding balance, then we reserve the right to suspend your account and/or take
                                                                         appropriate legal action to seek to recover the debt, in full, from you.
                                                                         Any amount of winnings or losses relating to your bets on a Market will be rounded to the
                                                                         nearest two decimal places.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Matters
                                                                         beyond our reasonable control</p>
                                                                     <p>Without prejudice to our obligations contained within the various laws and regulations of the
                                                                         jurisdictions where we are licensed, we are not liable for any loss or damage that you may
                                                                         suffer because of any: act of God; power cut; trade or labor dispute; act, failure or
                                                                         omission of any government or authority; obstruction or failure of telecommunication
                                                                         services; or any other delay or failure caused by a third party or otherwise outside of our
                                                                         control. In such an event, we reserve the right to cancel or suspend our services without
                                                                         incurring any liability.
 
                                                                         We are not liable for the failure of any equipment or software howsoever caused, wherever
                                                                         located or administered, or whether under our direct control or not, that may prevent the
                                                                         operation of the Services, impede the placing of offers for bets or the matching of bets, or
                                                                         prevent you from being able to contact us.
                                                                         We will not be liable for any failure to perform by a third party to our Agreement.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Indemnity
                                                                     </p>
                                                                     <p>You agree to indemnify and hold us and our associated companies, affiliates, officers,
                                                                         directors, agents and employees harmless from any liabilities, claims, losses or demands
                                                                         made by any third party arising out of your breach of this Agreement (including documents
                                                                         incorporated by reference) or out of your violation of any law or the rights of any third
                                                                         party.
                                                                         You acknowledge that 1xbat and its affiliates will hold information with respect to your
                                                                         identity, including but not limited to your name, address and payment details. You agree
                                                                         that we rely on this information in entering into this Agreement and you agree to hold us
                                                                         harmless against any falsehood or inaccuracy contained in the information you provide us.
                                                                     </p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Limitation
                                                                         of Liability</p>
                                                                     <p>We exclude all representations and warranties as to the satisfactory quality and/or fitness
                                                                         for its intended purpose and/or accuracy and completeness of the Services.
                                                                         We will not be liable to you for any loss that you may incur as a result of misuse of your
                                                                         password, or in the case of the Telephone Service, of your telephone access number, and we
                                                                         accept no liability resulting from its unauthorized use, whether fraudulent or otherwise.
                                                                         In no event will we, or any of our suppliers, accept any liability however arising for any
                                                                         losses you may incur as a result of using the Services. Under no circumstances will our
                                                                         liability under this Agreement or for breach of contract, tort, equity or otherwise exceed
                                                                         your exposure limit as specified in your account. Under no circumstances will we be liable
                                                                         for any indirect, special or consequential damages, loss of profits (direct or indirect) or
                                                                         the benefit of any bet arising from breach of contract, negligence, equitable duty
                                                                         (including for the avoidance of doubt in relation to any bet(s) or Market(s) voided by us)
                                                                         or other liability even if we had been advised of or known (or should have known) of the
                                                                         possibility of such damages or loss.
                                                                         Nothing in this Agreement excludes our liability with respect to death and personal injury
                                                                         resulting from our negligence, or that of our employees, agents or subcontractors.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Intellectual
                                                                         property</p>
                                                                     <p>1xbat owns all copyright and related rights in and to our site, including, without
                                                                         limitation, rights in databases and all rights in any price data and related content on our
                                                                         site except for certain third-party rights (referred to below);
                                                                         a) all trade mark rights, whether registered or unregistered, in and to 1xbat and the
                                                                         1xbat logo; and
                                                                         b) the domain name www.crickex.com which is our uniform resource locator ("URL").
 
                                                                         The following use of price data or any other data or content from our site or via the API or
                                                                         any other 1xbat data from any other source is strictly prohibited without our prior
                                                                         consent:
                                                                         a) commercial use by any person; and/or
                                                                         b) any use for any purpose by a competitor of the 1xbat group of companies, or an employee,
                                                                         contractor or agent of any such competitor ("Restricted Person"), provided always that
                                                                         Restricted Persons may place bets.
 
                                                                         Screen scraping, web scraping or any other automated or manual collection of 1xbat Data,
                                                                         for commercial or personal use, by any person is expressly prohibited.
                                                                         Any unauthorized use of any of the above rights may result in prosecution or other action
                                                                         being taken against you.
                                                                         You hereby assign to 1xbat absolutely, any and all copyright and other intellectual
                                                                         property rights throughout the world in all media whether now known or hereafter developed,
                                                                         for the full period of copyright, including by way of present assignment of future copyright
                                                                         and all other rights whatsoever, in any offers for bets or bets placed by you on our site.
                                                                         Any data licensed to 1xbat from third parties is provided for use on our site only and may
                                                                         not be used for any commercial purposes without the consent of such third parties.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Information
                                                                         Services</p>
                                                                     <p>1xbat from time to time may provide you with access to various information and content. This
                                                                         may include form data, results, video streaming, upcoming events, the times and dates of
                                                                         events, current scores, time elapsed, and other information relating to a sporting event.
                                                                         Such information may be supplied:
                                                                         a) via our site (including micro-sites and as part of any runner data and/or results
                                                                         service), emails or any other means of communication; and/or
                                                                         b) via links to third party websites.
 
                                                                         Such information is described in these Terms and Conditions collectively as the
                                                                         "Information".
                                                                         The Information is provided by 1xbat or is sourced from a third party. Although some
                                                                         Information may be described as "live", or it may be implied that such Information is
                                                                         "live", you should be aware that this data may be subject to a time delay. The Information
                                                                         may also be inaccurate. You should also be aware that other 1xbat users may have access to
                                                                         pictures and/or data that is faster and/or more accurate than the Information. The
                                                                         Information is supplied "as is" and is provided for guidance only. To the fullest extent
                                                                         permitted by law, 1xbat makes no representations or warranties of any kind, including but
                                                                         not limited to, the accuracy, quality, completeness or timeliness of the Information.
                                                                         If you rely on the Information to place bets, you do so entirely at your own risk. 1xbat
                                                                         shall not be liable for any action taken or not taken by you as a result of your reliance on
                                                                         the Information or for any loss or damage (direct or indirect) suffered by you as a result
                                                                         of your use of the Information.
                                                                         Any links to third party websites does not constitute an endorsement by 1xbat of any
                                                                         products or services available on such websites. You use such websites at your own risk and
                                                                         1xbat takes no responsibility for the content on, or use of, such websites.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Assignment
                                                                     </p>
                                                                     <p>You may not assign any part of this Agreement without 1xbat' written consent but 1xbat may
                                                                         assign any or all of this Agreement to any third party at any time. In the event of an
                                                                         assignment by 1xbat, we will notify of this change and, if you do not agree to this, then
                                                                         you will not be able to continue to use our services, but you shall always be able to
                                                                         withdraw your funds subject to these Terms and Conditions.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Severability
                                                                     </p>
                                                                     <p>In the event that any provision of this Agreement is deemed by any competent authority to be
                                                                         unenforceable or invalid, the relevant provision shall be modified to allow it to be
                                                                         enforced in line with the intention of the original text to the fullest extent permitted by
                                                                         applicable law. The validity and enforceability of the remaining provisions of this
                                                                         Agreement shall not be affected.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Dispute
                                                                         resolution</p>
                                                                     <p>In the event of a dispute arising between us and you, we each agree to follow the procedure
                                                                         set out in our Dispute Resolution policy as amended from time to time.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">Amendments
                                                                     </p>
                                                                     <p>We reserve the right to make immaterial changes to our site and all guides and policies
                                                                         included on our site, including this Agreement at any time.</p>
                                                                 </div>
                                                                 <div>
                                                                     <p className="text-capitalize font-weight-bold info-expension-panel-content-title mb-1">No waiver
                                                                     </p>
                                                                     <p>No failure or delay by a party to exercise any of its rights under this Agreement shall
                                                                         operate as a waiver thereof and no single or partial exercise of any such right shall
                                                                         prevent any other or further exercise of that or any other right.</p>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                              
                                            : ""}
                    </div>
                </div>
            </div>

        </LayoutNew>
    );
};

export default Info;
