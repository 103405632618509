import React from 'react';
import './Chat.css';
import './chatStyle.css';
import bannerImg from './img/banner.jpg';
import jokertImg1 from './img/1720774335720-setA.jpg';
import jokertImg2 from './img/1720774347885-setC.jpeg';
import jokertImg3 from './img/1722857781725-girl ima.webp';
import cricxoImg from './img/logo-1xbat.png';
import shareImg from './img/share-icon.svg';
import { Link ,useNavigate } from 'react-router-dom';

function Chat() {  

    const navigate = useNavigate();
    function handleClick(){
        // Perform any logic you need here
        console.log('Button clicked!');
        // Redirect to the About page
        navigate('/chat-support');
    }

  return (
    <div className='chat-main-div'>
      
    <div className="psize">
      <div className="chat-head">
          <img src={cricxoImg} width="160" alt="" />
          <span className="chat-u">
              <img src={jokertImg1} alt="" />
              <img src={jokertImg2} alt="" />
              <img src={jokertImg3} alt="" />
          </span>
      </div>
      <div className="chat-b">
          <h1>Hi Guest User, <span>How may i assist you?</span></h1>
          <div className="send-box">
              <input placeholder="Send us a message" onClick={handleClick} />
              <button><svg _ngcontent-ng-c429248538="" xmlns="http://www.w3.org/2000/svg" width="17" height="16"
                      fill="none" viewBox="0 0 17 16" color="linkColor">
                      <path _ngcontent-ng-c429248538="" fill="currentColor" fillRule="evenodd"
                          d="m4.563 14.605 9.356-5.402c1-.577 1-2.02 0-2.598L4.563 1.203a1.5 1.5 0 0 0-2.25 1.3v10.803a1.5 1.5 0 0 0 2.25 1.3M6.51 8.387 2.313 9.512V6.297L6.51 7.42c.494.133.494.834 0 .966"
                          clipRule="evenodd"></path>
                  </svg></button>
          </div>
          <div className="alternet-sites">
              <a href="#_"> <span className="asite"> Alternate Site 1 <img src={shareImg} alt="" /></span></a>
              <a href="#_"> <span className="asite"> Alternate Site 1 <img src={shareImg} alt="" /></span></a>
              <a href="#_"> <span className="asite"> Alternate Site 1 <img src={shareImg} alt="" /></span></a>
          </div>

          <div className="promo">
              <h3>depo promo</h3>
              <img src={bannerImg} alt="" />
              <p>Cash Bonus Up To 50000</p>
              <b>Get ready for the upcoming exciting matches</b>
              <b>This promotion is available to all members...</b>
          </div>
      </div>
      <footer className="chat-footer">
          
          <Link to="/"><svg _ngcontent-ng-c429248538="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  fill="none" viewBox="0 0 24 24">
                  <mask _ngcontent-ng-c429248538="" id="62a9a" fill="#fff">
                      <path _ngcontent-ng-c429248538="" fillRule="evenodd"
                          d="M10.5 2.335 3 7.51c-.625.437-1 1.116-1 1.84V19.7C2 20.965 3.125 22 4.5 22h15c1.375 0 2.5-1.035 2.5-2.3V9.35c0-.724-.375-1.403-1-1.84l-7.5-5.175a2.69 2.69 0 0 0-3 0M7.316 14.366a.85.85 0 1 0-1.132 1.268A8.7 8.7 0 0 0 12 17.852a8.7 8.7 0 0 0 5.816-2.218.85.85 0 1 0-1.132-1.268A7 7 0 0 1 12 16.152c-1.8 0-3.44-.675-4.684-1.786"
                          clipRule="evenodd"></path>
                  </mask>
                  <path _ngcontent-ng-c429248538="" fill="currentColor" fillRule="evenodd"
                      d="M10.5 2.335 3 7.51c-.625.437-1 1.116-1 1.84V19.7C2 20.965 3.125 22 4.5 22h15c1.375 0 2.5-1.035 2.5-2.3V9.35c0-.724-.375-1.403-1-1.84l-7.5-5.175a2.69 2.69 0 0 0-3 0M7.316 14.366a.85.85 0 1 0-1.132 1.268A8.7 8.7 0 0 0 12 17.852a8.7 8.7 0 0 0 5.816-2.218.85.85 0 1 0-1.132-1.268A7 7 0 0 1 12 16.152c-1.8 0-3.44-.675-4.684-1.786"
                      clipRule="evenodd"></path>
                  <path _ngcontent-ng-c429248538="" fill="currentColor"
                      d="m3 7.51-.965-1.4-.01.007zm7.5-5.175L9.538.934l-.003.002zM21 7.51l.974-1.393-.009-.006zm-7.5-5.175.966-1.4-.004-.001zM6.116 14.434l1.268 1.132zm1.2-.068 1.133-1.268zm-1.132 1.268L5.05 16.902zm11.632 0 1.133 1.268zm.068-1.2-1.268 1.132zm-1.2-.068-1.133-1.268zM3.965 8.91l7.5-5.175L9.536.936l-7.5 5.175zm-.265.44c0-.12.063-.299.274-.447L2.026 6.117C.987 6.843.3 8.022.3 9.35zm0 10.35V9.35H.3V19.7zm.8.6a.9.9 0 0 1-.615-.227.5.5 0 0 1-.185-.373H.3c0 2.335 2.022 4 4.2 4zm15 0h-15v3.4h15zm.8-.6a.5.5 0 0 1-.185.373.9.9 0 0 1-.615.227v3.4c2.178 0 4.2-1.665 4.2-4zm0-10.35V19.7h3.4V9.35zm-.274-.447c.211.148.274.326.274.447h3.4c0-1.328-.687-2.507-1.726-3.233zm-7.491-5.169 7.5 5.175 1.93-2.798-7.5-5.175zm-1.073.002a.99.99 0 0 1 1.076 0L14.462.934a4.39 4.39 0 0 0-4.924 0zm-4.078 11.83a.85.85 0 0 1-1.2.068l2.265-2.536a2.55 2.55 0 0 0-3.6.203zm-.068-1.2c.35.313.38.85.068 1.2l-2.536-2.265a2.55 2.55 0 0 0 .203 3.6zM12 16.152c-1.8 0-3.44-.675-4.684-1.786l-2.265 2.536A10.4 10.4 0 0 0 12 19.552zm4.684-1.786A7 7 0 0 1 12 16.152v3.4c2.667 0 5.105-1.004 6.949-2.65zm-.068 1.2a.85.85 0 0 1 .068-1.2l2.265 2.536a2.55 2.55 0 0 0 .203-3.6zm1.2.068a.85.85 0 0 1-1.2-.068l2.536-2.265a2.55 2.55 0 0 0-3.6-.203zM12 17.852a8.7 8.7 0 0 0 5.816-2.218l-2.265-2.536A5.3 5.3 0 0 1 12 14.452zm-5.816-2.218A8.7 8.7 0 0 0 12 17.852v-3.4a5.3 5.3 0 0 1-3.551-1.354z"
                      mask="url(#62a9a)"></path>
              </svg> <span>Home</span> </Link>
              <Link to="/chat-support">
              <svg _ngcontent-ng-c429248538="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  fill="none" viewBox="0 0 24 24">
                  <mask _ngcontent-ng-c429248538="" id="b54da" fill="#fff">
                      <path _ngcontent-ng-c429248538="" fillRule="evenodd"
                          d="M19 2a3 3 0 0 1 3 3v15.806c0 1.335-1.613 2.005-2.559 1.062L15.56 18H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3z"
                          clipRule="evenodd"></path>
                  </mask>
                  <path _ngcontent-ng-c429248538="" fill="#1A1A1A"
                      d="m19.441 21.868 1.2-1.204zM15.56 18v-1.7h.702l.498.496zM20.3 5A1.3 1.3 0 0 0 19 3.7V.3A4.7 4.7 0 0 1 23.7 5zm0 8.956V5h3.4v8.956zm0 2.544v-2.544h3.4V16.5zm0 4.306V16.5h3.4v4.306zm.341-.142a.23.23 0 0 0-.218-.043.23.23 0 0 0-.123.185h3.4c0 2.848-3.441 4.277-5.459 2.267zm-3.882-3.868 3.882 3.868-2.4 2.409-3.882-3.869zM5 16.3h10.559v3.4H5zM3.7 15A1.3 1.3 0 0 0 5 16.3v3.4A4.7 4.7 0 0 1 .3 15zm0-10v10H.3V5zM5 3.7A1.3 1.3 0 0 0 3.7 5H.3A4.7 4.7 0 0 1 5 .3zm14 0H5V.3h14z"
                      mask="url(#b54da)" className="13e2ddcdfill"></path>
                  <path _ngcontent-ng-c429248538="" fill="#1A1A1A" fillRule="evenodd"
                      d="M17 7a.85.85 0 0 1 0 1.7H7A.85.85 0 1 1 7 7zm-5 4a.85.85 0 0 1 0 1.7H7A.85.85 0 0 1 7 11z"
                      clipRule="evenodd" className="13e2ddcdfill"></path>
              </svg>
              <span>Messages</span>
            </Link>
            <a href="#_">
              <svg _ngcontent-ng-c429248538="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  fill="none" viewBox="0 0 24 24">
                  <circle _ngcontent-ng-c429248538="" cx="12" cy="12" r="9.65" stroke="#1A1A1A" strokeWidth="1.7"
                      className="f88d3aaastroke 54ad2da6circle"></circle>
                  <path _ngcontent-ng-c429248538="" stroke="#1A1A1A" strokeLinecap="round" strokeWidth="1.7"
                      d="M9.664 8.576a2.41 2.41 0 1 1 4.102 2.39l-1.075 1.104c-.326.322-.765.76-.765 1.544v.364"
                      className="f88d3aaastroke 937c870anegative"></path>
                  <circle _ngcontent-ng-c429248538="" cx="11.927" cy="16.884" r="0.884" fill="#1A1A1A"
                      className="b005cf98fill 937c870anegative"></circle>
              </svg>
              <span>Help</span>
            </a>
      </footer>
    </div>
    </div>
  );
}

export default Chat;
