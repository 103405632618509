import React, { useContext, useMemo, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import AuthContext from "../context/AuthContext";
import NoEvent from "./NoEvent";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
const SearchBar = () => {
  const { searchTab, setSearchTab } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 500), []);

  const getData = async (value) => {
    const { status, data } = await apiGet(apiPath.searchEvent, {
      keyword: value,
    });
    if (data?.success) {
      setList(data?.results);
    }
  };

  function handleDebounceFn(inputValue) {
    getData(inputValue);
  }

  function handleChange(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }
  return (
    <div className="inner-sidebar-content" style={{marginTop: `53px`}}>
      <div className="search-input-outer">
        <input
          placeholder="Search teams, competitions, and more..."
          className="search-input"
          onChange={handleChange}
          value={value}
        />
        <RxCross2 onClick={() => setSearchTab(false)} size={25} color="#fff" />
      </div>
      <div className="d-flex p-3 leage-list main-casino-wrapper">
        <div className={`main-list w-100`}>
          {/* <h6 className="mb-3">Popular</h6> */}
          {list?.length > 0 ? (
            list?.map((item, index) => {
              return (
                <div className="w-100 match-list">
                  <ul className="p-0 m-0">
                    <li
                      onClick={() => {
                        navigate(
                          `/full-market/${item?.marketId}/${item?.eventId}`
                        );
                        setSearchTab(false);
                      }}
                    >
                      {item?.eventName}
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <NoEvent />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
