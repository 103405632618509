import React, { useContext, useState } from "react";
// import ExploreSlider from "../slider/ExploreSlider";
// import MoreService from "../../components/MoreService";
import BannerSlider from "./slider/BannerSlider";
import SlotSlider from "./slider/SlotSlider";
import TableEgameSlider from "./slider/TableEgameSlider";
// import PaymentMethods from "../PaymentMethods";
import LiveCashino from "./slider/LiveCashino";
import { DepositWithrawData } from "../Utils/constants";
import LayoutNew from "../components/shared/LayoutNew";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Button, Modal } from "react-bootstrap";
import velkieLogo from "../assets/images/favicon.jpg";
import sky from "../assets/images/sky-logo.png";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import CurrentNews from "../components/CurrentNews";
import GameSlider from "../components/GameSlider";
import FooterSection from "../components/FooterSection";
import MainBanner from "./slider/MainBanner";
import AuraGameSlider from "./slider/AuraGameSlider";
import referralIcon from "../assets/icons/icon-referral.svg";

const HomeNew = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setBr, lang, setEn, messagelist, setAnnouncement,handelShowReferBox } =
    useContext(AuthContext);
  const [show, setShow] = useState(false);
  return (
    <LayoutNew>
      
      <MainBanner />
      <CurrentNews
        message={messagelist}
        setAnnouncement={setAnnouncement}
      />
      {
        // !isEmpty(user) && (
      <div className="refer-box blinkreace" onClick={() => handelShowReferBox()} > <img src={referralIcon} style={{width:`19px`}}/> Refer</div>

        // )
      }
      <div className="main d-div">
        
      
        <GameSlider />
        {/* <LiveCashino />
        <BannerSlider /> 
        {/* <AuraGameSlider /> */}
        <TableEgameSlider />
        <SlotSlider />
        {/* <FooterSection /> */}
        
      </div>
      
    </LayoutNew>
  );
};

export default HomeNew;
