import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import BackLay from "./BackLay";
import AuthContext from "../context/AuthContext";
import { priceFormat } from "../Utils/constants";
const SportAccordian = ({
  item,
  index,
  keyTime,
  user,
  showOdds,
  activeKeys,
  handleSelect,
  setLimit,
  type,
  score,
}) => {
  const navigate = useNavigate();
  const { parly } = useContext(AuthContext);
  const { setShowBetDialog, setBetSlipObject } = useContext(BetSlipContext);
  const [runnerBackOdds, setRunnerBackOdds] = useState([]);
  const [position, setPosition] = useState([]);
  const [runnerLayOdds, setRunnerLayOdds] = useState([]);
  const [runner, setRunner] = useState([]);
  const [localLimit, setLocalLimit] = useState({});
  const [totalMatched, setTotalMatched] = useState(0);
  const getMatchOdds = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${item?.matchList[0]?.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          item?.matchList[0]?.eventId == socket_data?.eventId
        ) {
          if (socket_data.rt?.length) {
            const back_odds =
              socket_data.rt.filter((rt) => rt.ib && rt?.bv > 0) || [];
            const lay_odds =
              socket_data.rt.filter((rt) => !rt.ib && rt?.bv > 0) || [];
            setRunnerBackOdds(back_odds);
            setRunnerLayOdds(lay_odds);
            setTotalMatched(socket_data?.totalMatched);
            if (
              (back_odds?.length > 0 || lay_odds?.length > 0) &&
              keyTime == "in_play" &&
              !isEmpty(user)
            ) {
              setTimeout(() => {
                getBetPosition(item);
              }, 1000);
            }
          } else {
            setRunnerBackOdds([]);
            setRunnerLayOdds([]);
          }
        } else {
          setRunnerBackOdds([]);
          setRunnerLayOdds([]);
        }
      } else {
        setRunnerBackOdds([]);
        setRunnerLayOdds([]);
      }
    }
  };

  const getBetPosition = async (item) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${item?.matchList[0]?.eventId}&type=${item?.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        setPosition(response_users?.results?.betFair);
        setRunner((current) =>
          response_users?.results?.betFair?.length > 0
            ? current.map((res) => {
                let obj = response_users?.results?.betFair.find(
                  (item) => item?.selectionId == res?.SelectionId
                );
                return {
                  ...res,
                  position:
                    obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                  newPosition: 0,
                };
              })
            : current.map((res) => {
                return {
                  ...res,
                  position: 0,
                  newPosition: 0,
                };
              })
        );
      }
    }
  };

  useEffect(() => {
    if (
      item?.matchList[0]?.runners?.length > 0 &&
      keyTime == "in_play" &&
      item?.matchList[0]?.eventName.split(" v ")[0] ==
        item?.matchList[0]?.runners[0]?.RunnerName &&
      showOdds &&
      !parly
    ) {
      setRunner(item?.matchList[0]?.runners);
    }
  }, [item, parly]);
  useEffect(() => {
    if (
      index == 0 &&
      keyTime == "in_play" &&
      item?.matchList[0]?.eventName?.split(" v ")[0] ==
        item?.matchList[0]?.runners[0]?.RunnerName &&
      showOdds &&
      !parly
    ) {
      getMatchOdds();
      if (runnerBackOdds?.length > 0 || runnerLayOdds?.length > 0) {
        const interval = setInterval(() => getMatchOdds(), 10000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [keyTime, item, parly]);

  const handelChange = (odds, type, item, object) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setLimit(localLimit);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: item,
          betType: type,
          playType: "betFair",
          object: {
            ...item,
            ...odds,
            teamName: item?.RunnerName,
            eventId: object?.eventId,
            marketId: object?.marketId,
          },
        }));
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div key={index} className="bookmaker p-sm-2 p-1 sport-accordian">
        <Accordion
          activeKey={activeKeys}
          onSelect={(e) => handleSelect(e, type)}
        >
          <Accordion.Item eventKey={index}>
            <Accordion.Header>
              {" "}
              <span className="sport-counter bg-yellow ">
                {item?.matchList?.length}
              </span>
              {item?.seriesName}
            </Accordion.Header>
            <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
              {item?.matchList
                ?.filter((el) => !el.eventName.includes(" T10 "))
                .map((object, index) => {
                  return (
                    <div className="sport-accord-body">
                      <div className="position-relative">
                        <div
                          onClick={() =>
                            navigate(
                              `/full-market/${object?.marketId}/${object?.eventId}`
                            )
                          }
                          className="d-flex justify-content-between align-items-center"
                        >
                          {!parly && (
                            <div
                              className="fave bg-star-sprit-black  position-absolute start-0"
                              style={{
                                marginLeft: "-27px",
                                marginTop: "-16px",
                              }}
                            ></div>
                          )}
                          <div className={parly ? "ps-1" : "ps-4"}>
                            <div
                              onClick={() =>
                                navigate(
                                  `/full-market/${object?.marketId}/${object?.eventId}`
                                )
                              }
                              className="icon-sports d-flex align-items-center"
                            >
                              <div>
                                <img
                                  src="../assets/images/casino-icon/youtube.png"
                                  alt=""
                                />
                                {!parly && (
                                  <>
                                    <img
                                      src="../assets/images/casino-icon/f.png"
                                      alt=""
                                    />
                                    <img
                                      src="../assets/images/casino-icon/b.png"
                                      alt=""
                                    />
                                    <img
                                      src="../assets/images/casino-icon/s.png"
                                      alt=""
                                    />
                                  </>
                                )}
                              </div>
                              <span
                                className={`in-play ms-1 ${
                                  keyTime == "in_play"
                                    ? "in-play"
                                    : keyTime == "today"
                                    ? keyTime
                                    : "tommorow"
                                }`}
                              >
                                {keyTime == "in_play"
                                  ? "In-play"
                                  : keyTime == "today"
                                  ? `${obj?.matchDateTime(
                                      object?.eventDateTime,
                                      "same"
                                    )}`
                                  : obj?.dateFormat(object?.eventDateTime)}
                              </span>
                            </div>
                            <span>{object?.eventName}</span>
                          </div>

                          <div className="d-flex align-items-center">
                            {score?.length > 0 &&
                              !isEmpty(
                                score?.find((res) => {
                                  return res?.eventId == object?.eventId;
                                })
                              ) && (
                                <div className="counter">
                                  {
                                    score?.find((res) => {
                                      return res?.eventId == object?.eventId;
                                    })?.score
                                  }
                                </div>
                              )}

                            <Button className="bg-transparent text-dark border-0 fs-4 px-2 pt-0 pe-0">
                              <FaAngleRight />
                            </Button>
                          </div>
                        </div>

                        {object?.runners?.length > 0 &&
                          keyTime == "in_play" &&
                          object?._id == item?.matchList[0]?._id &&
                          item?.matchList[0]?.eventName?.split(" v ")[0] ==
                            item?.matchList[0]?.runners[0]?.RunnerName &&
                          showOdds && item?.matchList[0]?.matchOdds== "on" &&
                          (runnerBackOdds?.length > 0 ||
                            runnerLayOdds?.length > 0) && (
                            <div className="sports-team-table">
                              <div className="match-odd-table bg-white p-3 px-0 py-1">
                                <div className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100">
                                  <div
                                    className="half-table"
                                    style={{
                                      display: "flex",
                                      flex: "1",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <h4 className="text-left">Match Odds</h4>
                                    <strong className="px-2">
                                      {totalMatched > 0 &&
                                        `${priceFormat(totalMatched)}`}
                                    </strong>
                                    {totalMatched < 10000 && (
                                      <strong className="bg-danger rounded text-white low-button">
                                        Low
                                      </strong>
                                    )}
                                  </div>
                                  <span
                                    style={{ width: "64px" }}
                                    className="text-center"
                                  >
                                    Back
                                  </span>
                                  <span
                                    style={{ width: "64px" }}
                                    className="text-center"
                                  >
                                    Lay
                                  </span>
                                </div>
                                <ul className="mb-2 rounded position-relative p-0">
                                  {runner?.map((item) => {
                                    const odds_back = runnerBackOdds.filter(
                                      (todd) => todd.ri === item.SelectionId
                                    );
                                    const odds_lay = runnerLayOdds.filter(
                                      (todd) => todd.ri === item.SelectionId
                                    );
                                    return (
                                      <li>
                                        <h2>
                                          {item?.RunnerName}{" "}
                                          {(Math?.abs(item?.position) > 0 ||
                                            Math.abs(item?.newPosition) >
                                              0) && (
                                            <span
                                              className={`d-block py-1 ${
                                                item?.position > 0
                                                  ? "text-green team-red-score"
                                                  : "team-red-score"
                                              }`}
                                            >
                                              {Math?.abs(item?.position) > 0
                                                ? Math?.abs(
                                                    item?.position
                                                  ).toFixed(2)
                                                : ""}
                                              {Math.abs(item?.newPosition) >
                                                0 && (
                                                <span
                                                  className={
                                                    item?.newPosition > 0
                                                      ? "text-green"
                                                      : ""
                                                  }
                                                >
                                                  {" "}
                                                  {">"}{" "}
                                                  {Math.abs(
                                                    item?.newPosition
                                                  ).toFixed(2)}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </h2>
                                        {/* <div className="lay-back-table">
                                        <div
                                          onClick={() =>
                                            handelChange(
                                              odds_back[0],
                                              "back",
                                              item,
                                              object
                                            )
                                          }
                                          className="back"
                                        >
                                          <strong>{odds_back[0]?.rt}</strong>
                                          <span className="d-block">
                                            {odds_back[0]?.bv}
                                          </span>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handelChange(
                                              odds_lay[0],
                                              "lay",
                                              item,
                                              object
                                            )
                                          }
                                          className="lay"
                                        >
                                          <strong>{odds_lay[0]?.rt}</strong>
                                          <span className="d-block">
                                            {odds_lay[0]?.bv}
                                          </span>
                                        </div>
                                      </div> */}
                                        <BackLay
                                          component={"betfair"}
                                          type="sport"
                                          index={index}
                                          item={object}
                                          handelChange={handelChange}
                                          odds_lay={odds_lay}
                                          odds_back={odds_back}
                                          limit={localLimit}
                                        />
                                      </li>
                                    );
                                  })}
                                  {runnerBackOdds?.length > 0 ||
                                  runnerLayOdds?.length > 0 ? (
                                    ""
                                  ) : (
                                    <div className="overlay-match"></div>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default SportAccordian;
