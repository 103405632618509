import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineDownload } from "react-icons/ai";
const Info = ({ handleShowInfo, sportRules }) => {
  return (
    <div className="info-detail overflow-hidden">
      <div className="title position-relative">
        <h4>{sportRules ? "Rules of Sportbook" : "Fancybet Rule"}</h4>
        <Button
          className="bg-transparent border-0 position-absolute end-0 top-0 pt-1 fs-4 pe-3"
          onClick={() => handleShowInfo()}
        >
          <RxCross2 />
        </Button>
      </div>
      <div className={sportRules ? "info-body pt-0" : "info-body pt-sm-5 pt-3"}>
        {sportRules ? (
          <ul className="p-0 m-0 sport-rules-sec">
            {Array(30)
              .fill()
              .map((item, index) => {
                return (
                  <li>
                    <span>Cricket InPlay</span>
                    <Button className="bg-yellow">
                      <AiOutlineDownload />
                    </Button>
                  </li>
                );
              })}
          </ul>
        ) : (
          <ol className="info-rule">
            {Array(10)
              .fill()
              .map((item, index) => {
                return (
                  <li>
                    Once all session/fancy bets are completed and settled there
                    will be no reversal even if the Match is Tied or is
                    Abandoned.
                  </li>
                );
              })}
          </ol>
        )}
      </div>
    </div>
  );
};

export default Info;
