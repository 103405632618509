import React ,{useState} from "react";
import { useTranslation } from "react-i18next";
import { Link,useNavigate } from "react-router-dom";
import gif from "../assets/images/image_95581.gif";
const FooterSection = () => {
  const { t } = useTranslation();
  const [showImg, setShowImg] = useState(true);
  const navigate = useNavigate();
  return (
    <>
    {/* {showImg &&
      <div className="hot-game float-banner">
      <a className="close" onClick={()=>setShowImg(false)}>✖</a>
        <img src={gif} alt="" onClick={() => navigate("/all-casino/hot")}/>
      </div>
      } */}
    <div class="pay">
 
      
      <div class="payment-inner gaming-lic">
        <div class="payment-row">
          <h2>{t("Gaming License")}</h2>
          <ul>
            <li>
              <Link to="/">
                <img src="../assets/images/gaming_license.png" alt="" />
              </Link>
            </li>
            
          </ul>
        </div>
        <div class="payment-row">
          <h2>{t("Responsible_Gaming")}</h2>
          <ul>
            
            <li>
              <Link to="/">
                <img src="../assets/images/age-limit.svg" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src="../assets/images/regulations.svg" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src="../assets/images/gamcare.svg" alt="" />
              </Link>
            </li>
          </ul>
        </div>
       
      </div>
      <h2>{t("Payment_Methods")}</h2>
      <ul className="paymentm">
        <li>
          <img src="../assets/images/pay1.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay2.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay3.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay4.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay5.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay6.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay7.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay8.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay9.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay10.png" alt="" />
        </li>
      </ul>
      <div class="language-sec border-top">
        <h6>
          <Link to="/info/about-us">{t("About_Us")}</Link>
        </h6>
        <h6>
          <Link to="/info/privacy-policy">{t("Privacy_Policy")}</Link>
        </h6>
        <h6>
          <Link to="/info/tnc">{t("Terms_Condition")}</Link>
        </h6>
        <h6>
          <Link to="/info/rule-regulation">{t("Rule_Regulations")}</Link>
        </h6>
        <h6>
          <Link to="/info/responsible-gaming">{t("Responsible_Gaming")}</Link>
        </h6>
        <h6>
          <Link to="/info/faq">{t("FAQ")}</Link>
        </h6>
        <h6>
          <Link to="/info/contact-us">{t("Contact_Us")}</Link>
        </h6>
        
        
        
        {/* <h6>
          <Link to="/affilate">{t("Affiliate")}</Link>
        </h6> */}
        <h6>
          <Link to="/">{t("1xbat_Blog")}</Link>
        </h6>
      </div>
   
    </div>
    </>
  );
};

export default FooterSection;
