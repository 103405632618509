import React ,{useEffect}from "react";


const BattingIframe = ({ detail }) => {
  useEffect(() => {


  }, []);
  return (
    // <div class="widgets">
    //         <div><div class="sr-widget sr-widget-1"></div></div>

    //     </div>
    <iframe
      className=" w-100"
      style={{
        // overflowX: "hidden",
        // overflow: "hidden",
        // height: "510px",
        // height: `${
        //   document.getElementsByClassName("widgets")[0]?.clientHeight
        // }px`,
        height:'180px',
        backgroundColor: "#000000",
      }}
      id="scoreId"
      // onLoad={load}
      src={detail?.scoreURL}
    ></iframe>
  );
};

export default BattingIframe;
