import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import MainBanner from "../pages/slider/MainBanner";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import moment from "moment";
import helpers from "../Utils/helpers";
import NoEvent from "../components/NoEvent";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { FaSearch ,FaBell,FaCog ,FaTv ,FaAngleLeft} from "react-icons/fa";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SportAccordian from "../components/SportAccordian";
import { io } from "socket.io-client";

import cricketIcon from "../assets/icons/icon-cricket.svg";
import soccerIcon from "../assets/icons/icon-sport.svg";
import tennisIcon from "../assets/icons/icon-tennis.svg";
const Exchange = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,setLimit
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [type, setType] = useState("inplay");
  const [gameType, setGameType] = useState("");
  const [inplayCountData, setInplayCountData] = useState({});
  const [sketon, setSketon] = useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const [selected, setSelected] = useState({});
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [activeKeys, setActiveKeys] = useState([0]);
  const [updated, setUpdated] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [activeKeysAll, setActiveKeysAll] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [allShow, setAllShow] = useState({
    cricket: false,
    tennis: false,
    soccer: false,
  });
  const getData = async () => {
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: gameType,
      type: type,
    });
    if (data?.success) {
      setActiveKeys([0]);
      if (gameTab == "all") {
        setUpdated({
          cricket:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "cricket")
            ) || [],
          tennis:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "tennis")
            ) || [],
          soccer:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "soccer")
            ) || [],
        });
        setActiveKeysAll({
          cricket: [0],
          tennis: [0],
          soccer: [0],
        });
      }
      setList(filteredArray(data?.results));
    }
  };
  const fetchAllLeagues = async () => {
    const { status, data } = await apiGet(apiPath.seriesList+"?domain=velkiiex.live");
    if (data?.success) {
      setList(data?.results);
      setUpdatedList(() => {
        setGameType("cricket");
          return filteredArray(
            data?.results?.filter((res) => {
              return res?.gameType == gameType;
            })
          );
        
      });
    }
  };

  useEffect(() => {
    if(type=="leagues"){
      fetchAllLeagues();
    }else{
      getData();
    }
    
  }, [type,parly,gameType]);



  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };


  const handleSelect = (event, type) => {
    if (gameTab !== "all") {
      if (activeKeys.includes(event)) {
        setActiveKeys(activeKeys.filter((res, index) => res !== event));
      } else {
        setActiveKeys([...activeKeys, event]);
      }
    } else {
      updateActive(event, type);
    }
  };
  const obj = {
    cricket: activeKeysAll?.cricket || [],
    tennis: activeKeysAll?.tennis || [],
    soccer: activeKeysAll?.soccer || [],
  };
  const updateActive = (event, type) => {
    if (obj[type].includes(event)) {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: obj[type].filter((res, index) => res !== event),
        });
      }
    } else {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [...activeKeysAll?.cricket, event],
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [...activeKeysAll?.tennis, event],
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [...activeKeysAll?.soccer, event],
        });
      }
    }
  };

  const handelChanges = (type) => {
    if (type == "cricket") {
      let temp = allShow?.cricket ? false : true;
      setAllShow({ ...allShow, cricket: temp });
      if (temp) {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: Array(updated?.cricket?.length)
            .fill(0)
            .map((res, index) => {
              return index;
            }),
        });
      } else {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [],
        });
      }
    } else if (type == "tennis") {
      let temp = allShow?.tennis ? false : true;
      setAllShow({ ...allShow, tennis: temp });
      if (temp) {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: Array(updated?.tennis?.length)
            .fill(0)
            .map((res, index) => {
              return index;
            }),
        });
      } else {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [],
        });
      }
    } else if (type == "soccer") {
      let temp = allShow?.soccer ? false : true;
      setAllShow({ ...allShow, soccer: temp });
      if (temp) {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: Array(updated?.soccer?.length)
            .fill(0)
            .map((res, index) => {
              return index;
            }),
        });
      } else {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [],
        });
      }
    } else {
      let temp = show ? false : true;
      setShow(temp);
      if (temp) {
        if (gameTab !== "all") {
          setActiveKeys(
            list?.map((res, index) => {
              return index;
            })
          );
        }
      } else {
        setActiveKeys([]);
      }
    }
  };


   // Socket
   const [checkRefresh, setCheckRefresh] = useState(true);
   const [socketObj, setSocketObj] = useState(null);
   const [score, setScore] = useState([]);
   const getScore = (message) => {
     setScore(message);
   };
   const getSocket = () => {
     if (checkRefresh) {
       if (!isEmpty(socketObj)) {
         socketObj.disconnect();
       }
       setCheckRefresh(false);
       let newSocket = "";
       let randomId = user?.user?._id ? user?.user?._id : 112233;
       if (!isEmpty(user)) {
         newSocket = io(
           `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
           {
             transports: ["websocket"],
             forceNew: true,
           }
         );
       } else {
         newSocket = io(`${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`, {
           transports: ["websocket"],
           forceNew: true,
         });
       }
       setSocketObj(newSocket);
       newSocket.on("scoreAll", getScore);
       newSocket.on("disconnect", function () {
         setCheckRefresh(true);
       });
       return () => newSocket.close();
     }
   };
 
   useEffect(() => {
     if (checkRefresh) {
       getSocket();
     }
   }, [checkRefresh]);
 
   document.addEventListener("visibilitychange", function () {
     if (!document.hidden && !checkRefresh) {
       setCheckRefresh(true);
       if (!isEmpty(socketObj)) {
         socketObj.disconnect();
       }
     } else {
       setCheckRefresh(false);
     }
   });

      const inPlayCount = async () => {
        const { status, data: response_users } = await apiGet(apiPath.inPlayCount);
        if (status === 200) {
          if (response_users.success) {
            if (response_users.results) {
              setInplayCountData(response_users.results);
            }
          }
        }
      };
      useEffect(() => {
        inPlayCount();  
      }, []);

  return (
    <LayoutNew>
      {searchTab ? (
          <SearchBar />
        ) : (
          <>
       <MainBanner />
       <div 
      //  style=" width: 100%; max-width: 360px; margin: auto; background: #000; min-height:96vh; padding:0px;"
       >
        <div 
        // style="height: 50px; display: block;"
        ></div>
        <div class="ftype d-flex justify-content-between">
        <ul>
                <li className={type == "inplay" ? "active" : ""}
              onClick={() => {
                setType("inplay");
                setParly(false)
                setGameType("");
              }}>
                    <span class="left-view">
                      <svg  width="8px" height="8px" viewBox="0 0 8 8"
                            version="1.1" 
                            class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                            <title   _mstHash="188" _mstVisible="7">tab-radius-l
                            </title>
                            <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-l"
                                    transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                    _mstVisible="8"></path>
                            </g>
                        </svg>
                        
                        </span>

                    Live <span class="right-view">
                        <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                            
                            class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                            <title data-v-16f79fce="">tab-radius-r</title>
                            <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-r">
                                </path>
                            </g>
                        </svg>
                    </span>
                </li>
                <li  className={type == "tomorrow" ? "active" : ""}
              onClick={() => {
                setType("tomorrow");
                setParly(false)
              }}>
                    <span class="left-view"><svg  width="8px" height="8px" viewBox="0 0 8 8"
                            version="1.1" 
                            class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                            <title   _mstHash="188" _mstVisible="7">tab-radius-l
                            </title>
                            <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-l"
                                    transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                    _mstVisible="8"></path>
                            </g>
                        </svg></span> Upcoming
                    <span class="right-view">
                        <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                            
                            class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                            <title data-v-16f79fce="">tab-radius-r</title>
                            <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-r">
                                </path>
                            </g>
                        </svg>
                    </span>
                </li>
                {
                  !parly &&
                  <li className={type == "leagues" ? "active" : ""}  onClick={() => {
                    setType("leagues");
                    setParly(false)
                  }}>
                        <span class="left-view"><svg  width="8px" height="8px" viewBox="0 0 8 8"
                                version="1.1" 
                                class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                                <title   _mstHash="188" _mstVisible="7">tab-radius-l
                                </title>
                                <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                    fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                    <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                        id="tab-radius-l"
                                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                        _mstVisible="8"></path>
                                </g>
                            </svg></span> Leagues <span class="right-view">
                            <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                                
                                class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                                <title data-v-16f79fce="">tab-radius-r</title>
                                <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                    fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                    <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                        id="tab-radius-r">
                                    </path>
                                </g>
                            </svg>
                        </span>
                    </li>
                }
               
                <li onClick={() => setParly(!parly)} className={parly  ? "parlyatab active" : "parlyatab"} >
                  <span class="left-view">
                    <svg  width="8px" height="8px" viewBox="0 0 8 8"
                            version="1.1" 
                            class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px" _mstVisible="6">
                            <title   _mstHash="188" _mstVisible="7">tab-radius-l
                            </title>
                            <g  id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="true" class="tab-bg" _mstVisible="7">
                                <path  d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-l"
                                    transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                                    _mstVisible="8"></path>
                            </g>
                        </svg></span> Parlay
                    <span class="right-view">
                        <svg data-v-16f79fce="" width="8px" height="8px" viewBox="0 0 8 8" version="1.1"
                            
                            class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px">
                            <title data-v-16f79fce="">tab-radius-r</title>
                            <g data-v-16f79fce="" id="Page-1" stroke="none" stroke-width="1" fill="none"
                                fill-rule="evenodd" data-is-selected="false" class="tab-bg">
                                <path data-v-16f79fce="" d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                                    id="tab-radius-r">
                                </path>
                            </g>
                        </svg>
                    </span>
                </li>
               
                
            </ul>
            <div className="settingtab d-flex">   
              <span><FaSearch   onClick={() => setSearchTab(true)}className="ms-auto" /></span>
              <span><FaBell   className="ms-auto" /></span>
              <span><FaCog   className="ms-auto" /></span>

            </div>
        </div>
        
        <div class="list-s">
       
            <div className={gameType == "cricket" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("cricket");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>34</strong>  {inplayCountData?.cricketInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        {/* <div class="sports-icon cricket"></div> */}
                        <img src={cricketIcon} />
                    </div>
                    <div class="list-box-name">Cricket</div>
                </a>
            </div>
            <div className={gameType == "soccer" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("soccer");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>50</strong>  {inplayCountData?.soccerInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        {/* <div class="sports-icon soccer"></div> */}
                        <img src={soccerIcon} />
                    </div>
                    <div class="list-box-name">Soccer</div>
                </a>
            </div>
            <div className={gameType == "tennis" ? "list-box active" : "list-box"}
              onClick={() => {
                setGameType("tennis");
              }}>
                <a href="#_">
                    <div class="tag-live">
                        <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                    </div>
                    <div class="icon-holder-big">
                        {/* <div class="sports-icon tennis"></div> */}
                        <img src={tennisIcon} />
                    </div>
                    <div class="list-box-name">Tennis</div>
                </a>
            </div>
            
        </div>
        
      
        {
          type!=="leagues" ?
          <>
          {!sketon?

       
                <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                <div>
                  {list?.length > 0 ? (
                    list?.map((item, index) => {
                      return (
                        <SportAccordian
                          profileData={profileData}
                          user={user}
                          keyTime={keyTime}
                          activeKeys={activeKeys}
                          handleSelect={handleSelect}
                          type="all"
                          showOdds={parly ? false : gameTab == "all" ? false : true}
                          item={item}
                          index={index}
                          setLimit={setLimit}
                          score={score}
                        />
                      );
                    })
                  ) : (
                    <NoEvent />
                  )}
                </div>
                </div>
      
        :
        <div className="sketonc"><Skeleton count={20} baseColor="#303030" highlightColor="#7e8081"/> </div>
        
        }
        </>
          
          :
          isEmpty(selected) && (
            <div>
             
              {updatedList?.length > 0 ? (
                <div className="d-flex p-3 leage-list main-casino-wrapper">
                  {/* <aside className="text-center">
                  <ul className="p-0 mb-0">
                    <li>C</li>
                    <li>I</li>
                    <li>O</li>
                  </ul>
                </aside> */}
                  <div
                    className={`main-list w-100 ${
                      updatedList?.length > 7 ? "mb-5" : ""
                    }`}
                  >
                    <h6 className="mb-3">Popular</h6>
                    {updatedList?.map((item, index) => {
                      return (
                        <div className="w-100 mb-1 match-list">
                          <ul className="p-0 m-0">
                            <li onClick={() => setSelected(item)}>
                              {item?.seriesName}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="d-flex p-3 justify-content-center align-items-center leage-list main-casino-wrapper">
                  <NoEvent />
                </div>
              )}
            </div>
          )}
  
          {/* second-level */}
          {!isEmpty(selected) && (
            <div className="second-level-leage">
              <div className="balance-label position-relative text-start ps-5">
                <button
                  onClick={() => setSelected({})}
                  className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
                >
                  <FaAngleLeft className="fs-3" />
                </button>
                {selected?.seriesName}
              </div>
  
              <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                <div
                  className={`main-list w-100 ${
                    selected?.matchList?.length > 7 ? "mb-5" : ""
                  }`}
                >
                  {selected?.matchList?.map((item, index) => {
                    return (
                      <div className="w-100 mb-3 match-list">
                        <ul className="p-0 m-0">
                          <li
                            onClick={() =>
                              navigate(
                                `/full-market/${item?.marketId}/${item?.eventId}`
                              )
                            }
                          >
                            {item?.runners?.map((res) => {
                              return (
                                <span className="d-block">{res?.RunnerName}</span>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
  
        
        
    </div></>)}
    </LayoutNew>
  );
};

export default Exchange;
