import React from "react";
import HeaderNew from "../HeaderNew";
import FooterNew from "../FooterNew";
import Footer from "../Footer";
import Loader from "../Loader";
import FooterSection from "../FooterSection";
import { useLocation } from "react-router-dom";

const LayoutNew = ({ children }) => {
  const location = useLocation();

  return <>
  <HeaderNew/>
  {children}
  <Loader/>
  <Footer />

  {location?.pathname == "/" || location?.pathname == "/sports" ?
    <FooterSection />
    :""
  }


  
  <FooterNew/>
  </>;
};

export default LayoutNew;
