import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { BiX } from "react-icons/bi";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
const SportWidget = ({ data, detail }) => {
  const navigate = useNavigate();
  var sportVisit = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
  };

  const { setActiveWidget, activeWidget, setShowWidget } =
    useContext(AuthContext);
  return (
    <div className="sports-widget">
      <div className="position-relative">
        <Slider {...sportVisit}>
          {data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    {
                      if (item?.marketId !== detail?.eventId) {
                        setActiveWidget(item?._id);
                        navigate(
                          `/full-market/${item?.marketId}/${item?.eventId}`
                        );
                      }
                    }
                  }}
                >
                  <ul
                    className={
                      activeWidget == item?._id
                        ? "widget-active m-0 sports-widget-ul"
                        : "m-0 sports-widget-ul"
                    }
                  >
                    <li>
                      <span className="text">
                        {" "}
                        {item?.eventName?.split(" v ")[0]}
                      </span>
                      <span>0</span>
                    </li>
                    <li>
                      <span className="text">
                        {" "}
                        {item?.eventName?.split(" v ")[1]}
                      </span>
                      <span>0</span>
                    </li>
                  </ul>
                </div>
              );
            })}
        </Slider>
        <div onClick={() => setShowWidget(false)} className="widgetCloseButton">
          <BiX />
        </div>
      </div>
    </div>
  );
};

export default SportWidget;
